import { TBetOperator } from "@matchem/matchem-common-ui";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface betOperatorState {
  value: TBetOperator | undefined;
}

const initialState: betOperatorState = {
  value: undefined,
};

export const betOperatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    updateBetOperator: (state, action: PayloadAction<TBetOperator>) => {
      state.value = action.payload;
    },
  },
});

export const { updateBetOperator } = betOperatorSlice.actions;

export const selectBetOperator = (state: RootState) => state.betOperator.value;

export default betOperatorSlice.reducer;
