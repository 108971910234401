import { Box, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { Container } from 'components/common/Container'
import { TitleAndRacesSkeleton } from 'components/races/RacesSkeleton'
import { TRacesMeetingsToggle } from 'models/races-meetings-toggle'
import { selectIsQueryPending, selectRaces } from 'reducers/races/racesSlice'
import { Meetings } from '../meetings/Meetings'
import { Races } from '../races/Races'

interface Props {
    racesOrMeetings: TRacesMeetingsToggle
}

const HomePageContentLoading = () => {
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '26px'
                }}
            >
                <div>
                    <TitleAndRacesSkeleton racesNumber={1} />
                </div>
                <div>
                    <TitleAndRacesSkeleton racesNumber={3} />
                </div>
                <div>
                    <TitleAndRacesSkeleton racesNumber={3} />
                </div>
            </Box>
        </Container>
    )
}

export const HomePageContent = ({ racesOrMeetings }: Props) => {
    const isRacesLoading = useAppSelector(selectIsQueryPending)
    const races = useAppSelector(selectRaces)

    if (isRacesLoading) return <HomePageContentLoading />

    if (!isRacesLoading && races.length === 0) {
        return (
            <Container>
                <Typography>Pas de courses pour ce jour</Typography>
            </Container>
        )
    }

    return racesOrMeetings === 'races' ? <Races /> : <Meetings />
}
