export function formatNumber(num: number | string): string {
  if (!num) return null as any;
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export function formatUnit(num: number | string, unit: string): string {
  switch (unit) {
    case "Euro":
      unit = "€";
      break;
    case "Livre Sterling":
      unit = "£";
      break;
    case "Dollar Australien":
    case "Dollar Canadien":
    case "Dollar HK":
    case "Dollar Singapourien":
    case "Dollar US":
      unit = "$";
      break;
    case "Dirham Marocain":
    case "Dirham des Emirats Arabes Unis":
    case "Dirham Emirats Arabes Unis":
      unit = "Dh";
      break;
    case "Couronne Danoise":
    case "Couronne Norvégienne":
    case "Couronne Suédoise":
      unit = "Kr";
      break;
    case "Couronne Tchèque":
      unit = "CZK";
      break;
    case "Peso Chilien":
    case "Peso Uruguayen":
      unit = "Pes";
      break;
    case "Franc Suisse":
      unit = "Fr";
      break;
    case "Rand":
      unit = "R";
      break;
  }
  return `${formatNumber(num)} ${unit}`;
}
