import { IRace } from '@matchem/matchem-common-ui'
import {
    createSelector,
    createSlice,
    isFulfilled,
    isPending,
    isRejected
} from '@reduxjs/toolkit'
import { selectFormattedDate } from '../date/dateSlice'
import { selectMeetings } from '../meetings/meetingsSlice'
import { RootState } from '../store'
import { getRacesByDateThunk } from './racesThunks'

export interface RacesState {
    value: Record<string, Array<IRace>>
    isQueryPending: boolean
}

const initialState: RacesState = {
    value: {},
    isQueryPending: false
}

export const racesSlice = createSlice({
    name: 'races',
    initialState,
    reducers: {
        updateRaces: (state, action) => {
            state.value[action.payload.selectedDate] = action.payload.races
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isPending(getRacesByDateThunk), (state) => {
                state.isQueryPending = true
            })
            .addMatcher(isRejected(getRacesByDateThunk), (state) => {
                state.isQueryPending = false
            })
            .addMatcher(
                isFulfilled(getRacesByDateThunk),
                (state, { payload }) => {
                    state.value = { ...state.value, ...payload }
                    state.isQueryPending = false
                }
            )
    }
})

export const { updateRaces } = racesSlice.actions

const racesValue = (state: RootState) => state.races.value
export const selectRacesByDate = createSelector(
    [selectFormattedDate, racesValue],
    (date, races) => races[date] ?? []
)

export const selectRaces = createSelector(
    [selectRacesByDate, selectMeetings],
    (races = [], meetings) =>
        races.filter(({ meetingId }) =>
            meetings.find(({ id }) => meetingId === id)
        )
)

export const selectRacesFromMeeting = (meetingId: number) =>
    createSelector(selectRaces, (races) =>
        races.filter((r) => r.meetingId === meetingId)
    )

export const selectQuinteRace = createSelector(selectRaces, (races) =>
    races.find((race) => race.isTQQ)
)

export const selectRaceById = (raceId: number | undefined) =>
    createSelector(selectRaces, (races) => races.find((r) => r.id === raceId))

export const selectIsQueryPending = (state: RootState) =>
    state.races.isQueryPending

export default racesSlice.reducer
