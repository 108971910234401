import { Bet, RaceTable, getFavoriteRunnerId } from '@matchem/matchem-common-ui'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BetinComponent } from 'components/betin'
import { useLocation } from 'hooks/location'
import { useSession } from 'hooks/session'
import { useBetinRaceOdds } from 'hooks/useBetinRaceOdds'
import { useEffect, useMemo, useState } from 'react'
import useDimensions from 'react-cool-dimensions'
import { useTranslation } from 'react-i18next'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'
import {
    selectDisableBet,
    selectOperatorOdds
} from 'reducers/config/configSlice'
import { selectMeetingsByRace } from 'reducers/meetings/meetingsSlice'
import { selectPage } from 'reducers/page/pageSlice'
import { selectPronosmartTips } from 'reducers/pronosmartTips/pronosmartTipsSlice'
import { selectRaceId } from 'reducers/race/raceSlice'
import { selectRaceById } from 'reducers/races/racesSlice'
import { selectRunnersByRaceID } from 'reducers/runner/runnerSlice'
import { getRunnersAsync } from 'reducers/runner/runnerThunks'
import { getRaceBettingTypesByRaceId } from 'services/firestore'
import theme from 'style/theme'
import { isFinished } from 'utils/race-status'
import { onRunnerClick } from 'utils/runner'
import { getAffiliateLink } from 'utils/url'
import { Container } from '../../common/Container'
import { ListCard } from '../../list/list'
import { RaceDividends } from './RaceDividends'
import { RaceInfo } from './RaceInfo'
import { raceTableColumn } from './raceTableColumn'

export const RaceDetail = () => {
    const raceId = useAppSelector(selectRaceId)!
    const race = useAppSelector(selectRaceById(raceId))
    const meeting = useAppSelector(selectMeetingsByRace(race?.meetingId!))
    const runners = useAppSelector(selectRunnersByRaceID(raceId))
    const betOperator = useAppSelector(selectBetOperator)
    const operatorOdds = useAppSelector(selectOperatorOdds)
    const disableBet = useAppSelector(selectDisableBet)
    const betinRaceOdds = useBetinRaceOdds(race?.id)
    const pronosmartTips = useAppSelector(selectPronosmartTips)!
    const { observe, currentBreakpoint } = useDimensions({
        // 0px - 785px (currentBreakpoint = XS), 785px - + (currentBreakpoint = SM)
        breakpoints: { XS: 0, SM: 785 },
        updateOnBreakpointChange: true
    })
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common')
    const [bets, setBets] = useState<Bet[]>([])
    const saddleListAsArraySliced = pronosmartTips?.tips.pronosmart?.saddleList
        .split(',')
        .slice(0, 5)
    const location = useLocation()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const sessionId = useSession('betin_widget')
    const page = useAppSelector(selectPage)

    useEffect(() => {
        dispatch(getRunnersAsync(raceId))
    }, [dispatch, raceId])

    useEffect(() => {
        let isCancelled = false
        const getBettingTypes = async () => {
            const betTypes = await getRaceBettingTypesByRaceId(raceId)
            if (!isCancelled) {
                setBets(betTypes || [])
            }
        }

        void getBettingTypes()
        return () => {
            isCancelled = true
        }
    }, [raceId])

    const favoriteRunnerId = useMemo(() => {
        if (!betinRaceOdds || !betOperator) return undefined
        return getFavoriteRunnerId(betinRaceOdds, betOperator)
    }, [betinRaceOdds, betOperator])

    const favoriteRunner = useMemo(() => {
        const betinRaceOdd = betinRaceOdds?.odds.find(
            ({ operator }) => operator === betOperator
        )
        return betinRaceOdd?.runners.find(
            ({ runnerId }) => runnerId === favoriteRunnerId
        )
    }, [betinRaceOdds, betOperator, favoriteRunnerId])

    const filteredRaceTableColumn = useMemo(
        () =>
            race?.isPremium
                ? raceTableColumn
                : raceTableColumn.filter((col) => col.id !== 'liveOdd'),
        [race?.isPremium]
    )

    const raceInfoOperatorLink = useMemo(() => {
        if (disableBet || !meeting || !race) return null
        return getAffiliateLink({
            operator: betOperator,
            meeting,
            race,
            runnerOdd: favoriteRunner,
            isMobile,
            componentId: 'co',
            page,
            sid: sessionId,
            country: location
        })
    }, [
        disableBet,
        betOperator,
        meeting,
        race,
        favoriteRunner,
        isMobile,
        page,
        sessionId,
        location
    ])

    const operatorOddLinks = useMemo(() => {
        if (
            disableBet ||
            !race ||
            !meeting ||
            !betinRaceOdds?.odds.length ||
            !betOperator
        )
            return {}
        const betinRaceOdd = betinRaceOdds?.odds.find(
            ({ operator }) => operator === betOperator
        )
        return (
            betinRaceOdd?.runners.reduce<Record<string, string>>(
                (acc, runnerOdd) => {
                    acc[runnerOdd.runnerId] = getAffiliateLink({
                        operator: betOperator,
                        meeting,
                        race,
                        runnerOdd,
                        isMobile,
                        componentId: 'co',
                        page,
                        sid: sessionId,
                        country: location
                    })
                    return acc
                },
                {}
            ) || {}
        )
    }, [
        betinRaceOdds,
        betOperator,
        meeting,
        race,
        isMobile,
        location,
        page,
        sessionId,
        disableBet
    ])

    if (!race || !meeting) return null

    return (
        <Box sx={{ mt: 1.25 }}>
            <RaceInfo
                race={race}
                meeting={meeting}
                runners={runners}
                bets={bets}
                operatorLink={raceInfoOperatorLink}
            />
            <BetinComponent
                component="block"
                options={{
                    defaultDisplay: 'light-compressed',
                    expandedDisplay: 'light-compressed',
                    context: {
                        raceNumber: race.number,
                        meetingNumber: meeting.pmuNumber,
                        runnerList: (saddleListAsArraySliced || []).join(',')
                    }
                }}
            />
            <Container ref={observe}>
                <ListCard>
                    {runners && betOperator && (
                        <>
                            <Typography variant="h2" gutterBottom>
                                {isFinished(race.raceStatus, race.status)
                                    ? t('race-detail-results')
                                    : t('runners')}
                            </Typography>
                            <RaceTable
                                columns={filteredRaceTableColumn}
                                race={race}
                                runners={runners}
                                betOperator={betOperator}
                                betinRaceOdd={betinRaceOdds}
                                operatorOddLinks={operatorOddLinks}
                                display={
                                    isFinished(race.raceStatus, race.status)
                                        ? 'results'
                                        : 'runners'
                                }
                                mobileDisplay={currentBreakpoint === 'XS'}
                                onClickCell={(runner) =>
                                    onRunnerClick(runner, raceId, dispatch)
                                }
                                onClickRow={(runner) =>
                                    onRunnerClick(runner, raceId, dispatch)
                                }
                                clickableOdds={
                                    !!Object.keys(operatorOddLinks).length
                                }
                                onClickOdd={({ id }) => {
                                    operatorOddLinks?.[id] &&
                                        window.open(operatorOddLinks[id])
                                }}
                            />
                        </>
                    )}
                </ListCard>
            </Container>
            {isFinished(race.raceStatus, race.status) && operatorOdds && (
                <RaceDividends race={race} operatorOdds={operatorOdds} />
            )}
        </Box>
    )
}
