import { IRace, IRunner, isFinished } from '@matchem/matchem-common-ui'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Horse } from 'models/horse'
import {
    getHorseById,
    getRace,
    getRunners,
    getRunnersByHorseId
} from 'services/firestore'
import { buildPastRaces, someRunnersInRace } from 'utils/race'

export const getHorseByIdAsync = createAsyncThunk(
    'horses/getHorseById',
    async (horseId: number) => {
        return await getHorseById(horseId)
    }
)

export const getHorsePastRacesDataAsync = createAsyncThunk(
    'horses/getHorsePastRacesData',
    async ({
        runner,
        horse,
        date
    }: {
        runner: IRunner
        horse?: Horse
        date: string
    }) => {
        let runnersList = await getRunnersByHorseId(runner.horseId, date)
        if (runner.cold) {
            runnersList = runnersList.map((runner: IRunner) => ({
                ...runner,
                cold: true
            }))
        }
        const racesList = (
            await Promise.all<IRace | null>(
                horse ? horse.runs.map((id) => getRace(id)) : []
            )
        )?.filter(
            (race: IRace | null) =>
                race &&
                isFinished(race.raceStatus, race.status) &&
                someRunnersInRace(runnersList, race)
        ) as IRace[]
        const pastRaces = await buildPastRaces(racesList, runnersList)
        const runnersInPastRace = await Promise.all(
            pastRaces.map(async (pastRace) => ({
                index: pastRace.id,
                runners: await getRunners(pastRace.id)
            }))
        )
        return { pastRaces, runnersInPastRace, runnersList }
    }
)
