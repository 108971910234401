import { useMemo } from "react";
import { buildImgUrl } from "utils/url";
import { TEmoji } from "models/runner";

interface Props {
  emoji: TEmoji;
}

export const Emoji = ({ emoji }: Props) => {
  const emojiPath = useMemo(() => getEmojiPath(emoji), [emoji]);

  return <img src={emojiPath} width={20} height={20} alt={"emoji"} />;
};

const getEmojiPath = (emoji: TEmoji) => {
  const MAPPING_EMOJI = {
    "1": "emoji-happy",
    "2": "emoji-neutral",
    "3": "emoji-sad",
  };

  return buildImgUrl(`/img/common/${MAPPING_EMOJI[emoji]}.svg`, "common-ui");
};
