import { styled, Typography } from "@mui/material";
import { getPronosmartTipsByRaceIdAsync } from "reducers/pronosmartTips/pronosmartTipsThunks";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectMeetingsByRace } from "reducers/meetings/meetingsSlice";
import { selectPage, updatePage } from "reducers/page/pageSlice";
import { selectRaceId, updateRaceId } from "reducers/race/raceSlice";
import {
  selectRaceById,
  selectRacesFromMeeting,
} from "reducers/races/racesSlice";

export const RaceSelector = () => {
  const dispatch = useAppDispatch();
  const raceId = useAppSelector(selectRaceId);
  const race = useAppSelector(selectRaceById(raceId as number));
  const meeting = useAppSelector(selectMeetingsByRace(race?.meetingId!));
  const races = useAppSelector(selectRacesFromMeeting(meeting?.id!));
  const currentPage = useAppSelector(selectPage);

  if (!race || !meeting) return null;
  return (
    <DivContainer>
      <TypographyMeeting variant="h4">
        {meeting.pmuNumber! > 0 ? `R${meeting?.pmuNumber}` : "PM"}
      </TypographyMeeting>
      <DivRaces>
        {races.slice(0, 10).map((raceItem) => (
          <span
            key={raceItem.id}
            onClick={() => {
              dispatch(updateRaceId(raceItem.id));
              dispatch(getPronosmartTipsByRaceIdAsync(raceItem.id));
              if (currentPage !== "race") dispatch(updatePage("race"));
            }}
          >
            <TypographyCurrentRace
              variant="h4"
              className={[
                raceItem.isTQQ && "isTqq",
                raceItem === race && "selected",
              ].join(" ")}
            >
              C{raceItem.number}
            </TypographyCurrentRace>
          </span>
        ))}
      </DivRaces>
    </DivContainer>
  );
};

const DivContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "18px",
  height: "36px",
  display: "flex",
  width: "100%",
  alignItems: "center",
  marginRight: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
  },
}));

const TypographyMeeting = styled(Typography)(({ theme }) => ({
  lineHeight: 0,
  margin: theme.spacing(0.3),
  fontWeight: "bold",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  height: "30px",
  width: "30px",
  overflow: "hidden",
}));

const DivRaces = styled("div")(() => ({
  lineHeight: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const TypographyCurrentRace = styled(Typography)(({ theme }) => ({
  lineHeight: 0,
  margin: theme.spacing(0.3),
  alignItems: "center",
  height: "30px",
  width: "30px",
  borderRadius: "50%",
  alighItems: "center",
  display: "flex",
  justifyContent: "center",

  "&:hover": {
    cursor: "pointer",
  },

  "&.selected": {
    cursor: "default",
    backgroundColor: "white",
    boxShadow: "0.5px 0.5px 2px 0px #c8c8c8",
  },

  "&.isTqq": {
    borderWidth: 2,
    borderColor: "red",
    borderStyle: "solid",
    borderRadius: "50%",
    height: "27px",
    width: "27px",
  },
}));
