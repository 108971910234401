import {
  differenceInSeconds,
  format,
  formatISO,
  formatRelative,
} from "date-fns";
import { enGB, fr } from "date-fns/locale";
import i18n from "i18next";

const locales: { [key: string]: Locale } = { fr: fr, en: enGB, undefined: fr };

/**
 * formatDate() method. - Return date with supplied format
 *
 * @param {Date | number | null} date
 * @param {String} formatDate - set format, default is "yyyy-MM-dd"
 * @param options
 * @return {String}
 */
export function formatDate(
  date?: Date | null,
  formatDate = "yyyy-MM-dd",
  options: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  } = { locale: locales[i18n.language] }
): string {
  const dt = date ?? new Date();
  const dtTimezone = dateTimezone(dt);
  return format(dtTimezone, formatDate, options);
}

/**
 * formatTitleDate()method. - Return date with supplied format
 *
 * @param {Date} date
 * @param {String} formatDate
 * @return {String}
 */
export function formatTitleDate(date: Date, formatDate = "eee d MMM"): string {
  const currentDateTimezone = dateTimezone(new Date());
  const dtTimezone = dateTimezone(date ?? new Date());
  return String(
    formatRelative(dtTimezone, currentDateTimezone, {
      locale: locales[i18n.language],
    }).split(" ")[0] +
      " " +
      format(dtTimezone, formatDate, { locale: locales[i18n.language] })
  );
}

/**
 * getCurrentDate() method. - Return the current date
 */
export function getCurrentDate() {
  return new Date();
}

/**
 * getCurrentDate() method. - Return the current date
 */
export function getCurrentISODate() {
  return formatISO(new Date());
}

/**
 * diffTimes() method. - Return minutes/seconds between two dates
 *
 * @param {Date} a - The first date
 * @param {Boolean} seconds - Return seconds if true (default false)
 * @param {Date} b - The second date (default current datetime)
 * @return {Number}
 */
export function diffTimes(
  a: Date,
  seconds = false,
  b: Date = new Date()
): number {
  return seconds
    ? differenceInSeconds(a, b) ?? 0
    : Math.ceil(differenceInSeconds(a, b) / 60) ?? 0;
}

export const dateTimezone = (date: Date) =>
  new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
