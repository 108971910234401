import { useCallback, useMemo, useState } from 'react'
import { selectDate } from 'reducers/date/dateSlice'
import { connectMeetingsRaces } from 'utils/meeting-races'
import { useAppSelector } from 'app/hooks'
import { selectMeetings } from 'reducers/meetings/meetingsSlice'
import { selectRaces } from 'reducers/races/racesSlice'
import { isImminent, isOngoing, isUpcoming } from 'utils/race-status'
import { isItemClosed } from 'utils/races-categorization'
import { Container } from '../common/Container'
import { ShowPastRaces } from './button/ShowPastRaces'
import { QuinteItem } from './QuinteItem'
import { RaceList } from './RaceList'
import { Box } from '@mui/material'
import { isToday } from 'date-fns'

export const Races = () => {
    const meetings = useAppSelector(selectMeetings)
    const races = useAppSelector(selectRaces)
    const selectedDate = useAppSelector(selectDate)
    const [showPastRaces, setShowPastRaces] = useState(false)
    const items = connectMeetingsRaces(races, meetings)
    const [isAnyClosedItem, isAnyOngoingOrUpcoming] = useMemo(() => {
        const isAnyClosedItem =
            items.filter(({ race, meeting }) =>
                isItemClosed(race, meeting, new Date())
            ).length > 1
        const isAnyOngoingOrUpcoming = races.some(
            (race) =>
                isOngoing(race.raceStatus) ||
                isImminent(race.raceStatus) ||
                isUpcoming(race.raceStatus)
        )
        return [isAnyClosedItem, isAnyOngoingOrUpcoming]
    }, [races, items])
    const clickToShowPastRaces = useCallback(
        () => setShowPastRaces((prevState) => !prevState),
        [setShowPastRaces]
    )

    return (
        <Container>
            <QuinteItem />
            {isAnyClosedItem &&
                isAnyOngoingOrUpcoming &&
                isToday(selectedDate) && (
                    <Box sx={{ float: 'right' }}>
                        <ShowPastRaces
                            showPastRaces={showPastRaces}
                            handleClick={clickToShowPastRaces}
                        />
                    </Box>
                )}
            <RaceList
                races={races}
                meetings={meetings}
                showPastRaces={showPastRaces}
            />
        </Container>
    )
}
