import { IRace } from "@matchem/matchem-common-ui";
import { IMeeting } from "models/meeting";

export const connectMeetingsRaces = (races: IRace[], meetings: IMeeting[]) =>
  races.map((race) => {
    const meeting = meetings.find((meeting) => race.meetingId === meeting.id)!;
    return {
      race,
      meeting,
    };
  });
