import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { dateTimezone, formatDate } from "utils/date";

export interface DateState {
  value: Date;
}

const initialState: DateState = {
  value: dateTimezone(new Date()),
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    updateDate: (state, action: PayloadAction<Date>) => {
      state.value = action.payload;
    },
  },
});

export const { updateDate } = dateSlice.actions;

export const selectDate = (state: RootState) => state.date.value;

export const selectFormattedDate = (state: RootState) =>
  formatDate(state.date.value);

export default dateSlice.reducer;
