import { TBetOperator } from '@matchem/matchem-common-ui'
import { AppProvider } from 'app/AppProvider'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Container } from 'components/common/Container'
import { Header } from 'components/header/Header'
import { Logo } from 'components/header/Logo'
import { HomePageContent } from 'components/home/Home'
import { HorseDetail } from 'components/horse/HorseDetail'
import { QuinteItem } from 'components/races/QuinteItem'
import { RaceDetail } from 'components/races/race-detail/RaceDetail'
import { useFireStore } from 'hooks/firestore'
import { useOperators } from 'hooks/operators'
import { useBetinWidget } from 'hooks/useBetinWidget'
import { IMeeting } from 'models/meeting'
import { TRacesMeetingsToggle } from 'models/races-meetings-toggle'
import { useEffect, useState } from 'react'
import { updateBetOperator } from 'reducers/betOperator/betOperatorSlice'
import { getBetinOddsAsync } from 'reducers/betinOdds/thunks'
import {
    selectConfig,
    updateAdsConsents,
    updateConfig,
    updateDisableBet,
    updateQuinteOnly,
    updateStatisticsConsents,
    updateWithScroll
} from 'reducers/config/configSlice'
import { selectFormattedDate } from 'reducers/date/dateSlice'
import { updateMeetings } from 'reducers/meetings/meetingsSlice'
import { TPage, selectPage } from 'reducers/page/pageSlice'
import { selectRaceId } from 'reducers/race/raceSlice'
import { selectIsQueryPending } from 'reducers/races/racesSlice'
import { fetchMeetings } from 'services/firestore'
import './App.css'
import { getRacesByDateThunk } from './reducers/races/racesThunks'

function App({ domElement }: { domElement?: Element | null }) {
    const dispatch = useAppDispatch()
    const attributes: any =
        Object.values(domElement?.attributes || {}).reduce(
            (acc, attr) => ({ ...acc, [attr.name]: attr.value }),
            {}
        ) || {}
    const attributesNames = Object.keys(attributes)

    const clientKey = attributes['data-key']
    const isClairefontaine = clientKey === 'clairefontaine'
    const [clairefontaineMeetingId, setClairefontaineMeetingId] = useState<
        number | null
    >(null)
    const statisticsConsents = attributes['data-statisticsconsents']
    const adsConsents = attributes['data-adsconsents']
    const withScroll = attributesNames.includes('data-scrollable')
    const quinteOnly = attributesNames.includes('data-quinteonly')
    const meetingsDefault = attributesNames.includes('data-meetingsdefault')
    const disableBet = attributesNames.includes('data-disablebet')

    const selectedDate = useAppSelector(selectFormattedDate)
    const currentPage = useAppSelector(selectPage)
    const config = useAppSelector(selectConfig)
    const [racesOrMeetings, setRacesOrMeetings] =
        useState<TRacesMeetingsToggle>(meetingsDefault ? 'meetings' : 'races')
    const { operators, selectedBetOperator } = useOperators()
    const isRacesLoading = useAppSelector(selectIsQueryPending)
    const raceId = useAppSelector(selectRaceId)
    useBetinWidget([currentPage, racesOrMeetings, isRacesLoading, raceId])

    useEffect(() => {
        dispatch(updateConfig(clientKey))
        dispatch(updateWithScroll(withScroll))
        dispatch(updateQuinteOnly(quinteOnly))
        dispatch(updateStatisticsConsents(statisticsConsents))
        dispatch(updateAdsConsents(adsConsents))
        dispatch(updateDisableBet(disableBet))
    }, [
        clientKey,
        dispatch,
        withScroll,
        quinteOnly,
        statisticsConsents,
        adsConsents,
        disableBet
    ])

    useEffect(() => {
        dispatch(getBetinOddsAsync(selectedDate))
    }, [dispatch, selectedDate])

    useEffect(() => {
        if (selectedBetOperator) {
            dispatch(updateBetOperator(selectedBetOperator as TBetOperator))
        }
    }, [dispatch, selectedBetOperator])

    useFireStore<IMeeting>({
        query: fetchMeetings(selectedDate),
        data: (meetings) => {
            if (isClairefontaine) {
                const clairefontaineMeet = meetings.find(
                    ({ name }) => name?.toLowerCase() === 'clairefontaine'
                )
                if (clairefontaineMeet?.id) {
                    setClairefontaineMeetingId(clairefontaineMeet.id)
                } else {
                    setClairefontaineMeetingId(null)
                }
            }
            return dispatch(updateMeetings({ selectedDate, meetings }))
        },
        deps: [selectedDate, isClairefontaine]
    })

    useEffect(() => {
        if (isClairefontaine) {
            dispatch(
                getRacesByDateThunk({
                    date: selectedDate,
                    isClairefontaine,
                    clairefontaineMeetingId
                })
            )
        } else {
            dispatch(getRacesByDateThunk({ date: selectedDate }))
        }
    }, [dispatch, selectedDate, isClairefontaine, clairefontaineMeetingId])

    const pageContent = (state: TPage) => {
        switch (state) {
            case 'home':
                return <HomePageContent racesOrMeetings={racesOrMeetings} />
            case 'race':
                return <RaceDetail />
            case 'horse':
                return <HorseDetail />
            default:
                return null
        }
    }

    if (operators.length === 0) return null
    if (!config) return null

    return (
        <AppProvider>
            <div
                className="matchem-widget-app"
                style={{ containerType: 'inline-size', paddingTop: '1rem' }}
            >
                {quinteOnly ? (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Logo inlineMode={true} />
                        </div>
                        <Container>
                            <QuinteItem />
                        </Container>
                    </div>
                ) : (
                    <div>
                        <header>
                            <Header
                                racesOrMeetings={racesOrMeetings}
                                setRacesOrMeetings={setRacesOrMeetings}
                            />
                        </header>
                        {pageContent(currentPage)}
                    </div>
                )}
            </div>
        </AppProvider>
    )
}

export default App
