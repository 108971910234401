import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'
import { selectBetinDividendsById } from 'reducers/betinDividends/selectors'
import { getBetInRaceDividendsAsync } from 'reducers/betinDividends/thunks'

export const useBetinDividends = (raceId: number) => {
    const dispatch = useAppDispatch()
    const betInDividends = useAppSelector(selectBetinDividendsById(raceId))

    useEffect(() => {
        const getBetInRaceFn = async () => {
            dispatch(getBetInRaceDividendsAsync(raceId))
        }
        if (!betInDividends) {
            void getBetInRaceFn()
        }
    }, [betInDividends, dispatch, raceId])

    return betInDividends
}
