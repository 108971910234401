import { Skeleton } from '@mui/material'

export const TitleSkeleton = () => {
    return (
        <Skeleton
            variant="rounded"
            width={120}
            height={22}
            sx={{ marginBottom: '0.35rem' }}
        />
    )
}

export const RacesSkeleton = ({
    racesNumber = 1
}: {
    racesNumber?: number
}) => {
    return (
        <>
            {new Array(racesNumber).fill('').map((_, key) => (
                <Skeleton
                    key={key}
                    height={40}
                    width="calc(100% - 16px)"
                    variant="rectangular"
                    sx={{ margin: '0px 8px 8px' }}
                />
            ))}
        </>
    )
}

export const TitleAndRacesSkeleton = ({
    racesNumber = 1
}: {
    racesNumber?: number
}) => {
    return (
        <>
            <TitleSkeleton />
            <RacesSkeleton racesNumber={racesNumber} />
        </>
    )
}
