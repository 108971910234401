import {
    BetinUrlParams,
    IBetinRunnerOdd,
    IMeeting,
    IRace,
    getBetinAffiliateLink
} from '@matchem/matchem-common-ui'
import slug from 'slug'
import { getCurrentPageId } from './tracking'

export const buildImgUrl = (url: string, path: string = 'statics'): string => {
    const hostName = 'https://www.paris-turf.com'
    const rootPath = url?.includes('http') ? '' : `${hostName}/${path}`
    const hash = '0.4.0'
        .split('')
        .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)
    return `${rootPath}${url}?${hash}`
}

export type AffiliateLinkParams = Pick<
    BetinUrlParams,
    'country' | 'sid' | 'operator'
> & {
    race: IRace
    meeting: IMeeting
    runnerOdd?: IBetinRunnerOdd
    page: string
    componentId: 'co'
    isMobile: boolean
}

export function getAffiliateLink({
    meeting,
    race,
    runnerOdd,
    componentId,
    isMobile,
    page,
    country,
    sid,
    operator
}: AffiliateLinkParams) {
    const baseUrl = process.env.REACT_APP_BETIN_AFFILIATION_ENGINE_LINK
    if (!baseUrl) return ''
    const betinUrlParams: BetinUrlParams = {
        mediaId: 'widget',
        path: getPath(),
        cid: componentId + '-' + getCurrentPageId(page),
        device: isMobile ? 'mobile' : 'desktop',
        meetingNumber: meeting.pmuNumber!.toString(),
        raceNumber: race.number!.toString(),
        raceDate: race.date!,
        raceCourse: meeting.name!,
        country,
        sid,
        operator
    }
    if (runnerOdd) {
        betinUrlParams.actor1 = runnerOdd.horseNumber?.toString()
        betinUrlParams.odd = runnerOdd.liveOdd?.toString()
    }
    return getBetinAffiliateLink(baseUrl, betinUrlParams)
}

export function openAffiliateLink(args: AffiliateLinkParams) {
    window.open(getAffiliateLink(args), '_blank')
}

export function getPath() {
    return encodeURI(window?.location.pathname + window?.location.search || '')
}

export const buildRaceUrl = (
    race: Pick<IRace, 'name' | 'uuid'>,
    meeting: Pick<IMeeting, 'name'>
): string => {
    return `https://www.paris-turf.com/course/${slug(
        `${meeting?.name}-${race?.name}`,
        {
            lower: true
        }
    )}-idc-${race?.uuid}`
}
