import { IPastRace, IRunner } from "@matchem/matchem-common-ui";
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { ApiStatus } from "models/api";
import { Horse } from "models/horse";
import { RootState } from "../store";
import { getHorseByIdAsync, getHorsePastRacesDataAsync } from "./horseThunks";

export interface HorseState {
  value: Horse | undefined | null;
  status: ApiStatus;
  pastRaces?: IPastRace[];
  runnersInPastRace?: { index: number; runners: IRunner[] }[];
  runnersList?: IRunner[];
}

const initialState: HorseState = {
  value: undefined,
  pastRaces: undefined,
  runnersInPastRace: undefined,
  runnersList: undefined,
  status: "idle",
};

export const horsesSlice = createSlice({
  name: "horses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHorseByIdAsync.fulfilled, (state, action) => {
        const horse = action.payload;
        if (horse) {
          state.value = horse;
        }
      })
      .addCase(getHorsePastRacesDataAsync.fulfilled, (state, action) => {
        const { pastRaces, runnersInPastRace, runnersList } = action.payload;
        state.pastRaces = pastRaces;
        state.runnersInPastRace = runnersInPastRace;
        state.runnersList = runnersList;
      })
      .addMatcher(
        isPending(getHorseByIdAsync, getHorsePastRacesDataAsync),
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isRejected(getHorseByIdAsync, getHorsePastRacesDataAsync),
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(
        isFulfilled(getHorseByIdAsync, getHorsePastRacesDataAsync),
        (state) => {
          state.status = "idle";
        }
      );
  },
});

export const selectHorse = (state: RootState) => state.horse.value;
export const selectHorsePastRaces = (state: RootState) => state.horse.pastRaces;
export const selectHorseRunnersInPastRace = (state: RootState) =>
  state.horse.runnersInPastRace;
export const selectHorseRunnersList = (state: RootState) =>
  state.horse.runnersList;

export default horsesSlice.reducer;
