import { IRunner } from "@matchem/matchem-common-ui";

export const runnerArrivalList = (arrival: string, runners: IRunner[]) => {
  let topFiveRunners: IRunner[] = [];
  arrival
    .split("-")
    .slice(0, 5)
    .map((arr) =>
      runners.map(
        (runner) =>
          arr === runner.saddle.toString() && topFiveRunners.push(runner)
      )
    );
  return topFiveRunners;
};
