import { configureStore } from '@reduxjs/toolkit'
import betOperatorReducer from './betOperator/betOperatorSlice'
import { betinDividendsReducers } from './betinDividends/slice'
import { betinOddsReducers } from './betinOdds/slice'
import configReducer from './config/configSlice'
import dateReducer from './date/dateSlice'
import horseReducer from './horse/horseSlice'
import meetingsReducer from './meetings/meetingsSlice'
import pageReducer from './page/pageSlice'
import pronosmartTipsReducer from './pronosmartTips/pronosmartTipsSlice'
import raceReducer from './race/raceSlice'
import racesReducer from './races/racesSlice'
import runnerReducer from './runner/runnerSlice'

export const reducerConfig = {
    date: dateReducer,
    config: configReducer,
    meetings: meetingsReducer,
    races: racesReducer,
    runner: runnerReducer,
    horse: horseReducer,
    page: pageReducer,
    race: raceReducer,
    betOperator: betOperatorReducer,
    pronosmartTips: pronosmartTipsReducer,
    betinOdds: betinOddsReducers,
    betinDividends: betinDividendsReducers
}

export const store = configureStore({
    reducer: reducerConfig,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
