import { IHeadCell } from '@matchem/matchem-common-ui'

export const raceTableColumn: IHeadCell[] = [
    {
        id: 'ranking',
        align: 'center',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['results'],
        sortable: true
    },
    {
        id: 'saddle',
        align: 'right',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'stats', 'odds'],
        sortable: true
    },
    {
        id: 'id',
        align: 'center',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results', 'stats', 'odds'],
        sortable: false
    },
    {
        id: 'saddle',
        align: 'right',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['results'],
        sortable: true
    },
    {
        id: 'horseName',
        align: 'left',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results', 'stats', 'odds'],
        sortable: true
    },
    {
        id: 'shoeing',
        align: 'left',
        specialties: ['A', 'M'],
        display: ['runners', 'results'],
        sortable: true
    },
    {
        id: 'blinkers',
        align: 'left',
        specialties: ['P', 'H', 'S', 'C'],
        display: ['runners', 'results'],
        sortable: true
    },
    {
        id: 'margin',
        align: 'center',
        specialties: ['P', 'H', 'S', 'C'],
        display: ['results'],
        sortable: false
    },
    {
        id: 'redkm',
        align: 'center',
        specialties: ['A', 'M'],
        display: ['results'],
        sortable: true
    },
    {
        id: 'sexAge',
        align: 'center',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results'],
        sortable: false
    },
    {
        id: 'draw',
        align: 'right',
        specialties: ['P'],
        display: ['runners', 'results'],
        sortable: true
    },
    {
        id: 'claimRating',
        align: 'center',
        specialties: ['P', 'H'],
        discipline: ['Galop'],
        display: ['runners'],
        sortable: false
    },
    {
        id: 'distance',
        align: 'center',
        specialties: ['A', 'M'],
        display: ['runners', 'results'],
        sortable: true
    },
    {
        id: 'claimRating',
        align: 'center',
        specialties: ['A', 'M'],
        discipline: ['Trot'],
        display: ['runners'],
        sortable: false
    },
    {
        id: 'weightKg',
        align: 'center',
        specialties: ['M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results'],
        sortable: false
    },
    {
        id: 'jockeyAllowance',
        align: 'center',
        specialties: ['M'],
        display: ['runners'],
        sortable: false
    },
    {
        id: 'driverName',
        align: 'left',
        specialties: ['A'],
        display: ['runners', 'results'],
        sortable: false
    },
    {
        id: 'jockeyName',
        align: 'left',
        specialties: ['M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results'],
        sortable: false
    },
    {
        id: 'formFigs',
        align: 'left',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners'],
        sortable: false
    },
    {
        id: 'handicapRatingKg',
        align: 'center',
        specialties: ['P', 'H', 'S', 'C'],
        display: ['runners', 'results'],
        sortable: true
    },
    {
        id: 'totalPrize',
        align: 'right',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results'],
        sortable: true
    },
    {
        id: 'trainerName',
        align: 'left',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results'],
        sortable: false
    },
    {
        id: 'emoji',
        align: 'center',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        discipline: ['Trot'],
        display: ['runners'],
        sortable: true
    },
    {
        id: 'liveOdd',
        align: 'right',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['runners', 'results', 'stats'],
        sortable: true
    },
    {
        id: 'liveOdd_PMU',
        align: 'center',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['odds'],
        sortable: true
    },
    {
        id: 'liveOdd_PMU_ONLINE',
        align: 'center',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['odds'],
        sortable: true
    },
    {
        id: 'liveOdd_GENYBET',
        align: 'center',
        specialties: ['A', 'M', 'P', 'H', 'S', 'C'],
        display: ['odds'],
        sortable: true
    }
]
