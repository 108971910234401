import { Paper, styled } from "@mui/material";
import { forwardRef, ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    return (
      <PaperContainer elevation={0} ref={ref}>
        {props.children}
      </PaperContainer>
    );
  }
);

const PaperContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 1.25, 2.25),
  borderRadius: theme.spacing(1),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 1, 1),
  },
}));
