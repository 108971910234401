import throttle from "lodash/throttle";
import { useEffect } from "react";
import { Query, onSnapshot } from "firebase/firestore";

export const useFireStore = <T>({
  query,
  data,
  deps,
}: {
  query: Query;
  data: (d: T[]) => any;
  deps: any[];
}) => {
  useEffect(() => {
    const unsubscribe = onSnapshot(query, (snap) => {
      const docs = snap.docs.map((doc) => doc.data()) as T[];
      const throttled = throttle(() => data(docs), 5000, {
        trailing: false,
      });
      throttled();
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [...deps]);
};
