import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./Arrows";
import React, { ContextType, ReactElement, useEffect, useRef } from "react";

export type HorizontalSelectorApi = ContextType<typeof VisibilityContext>;

interface Props {
  currentItemId: string;
  children: ReactElement<{
    itemId: string;
  }>[];
}

export const HorizontalSelector = ({ currentItemId, children }: Props) => {
  const apiRef = useRef<HorizontalSelectorApi>({} as HorizontalSelectorApi);

  useEffect(() => {
    if (apiRef.current && currentItemId) {
      apiRef.current.scrollToItem(
        apiRef.current.getItemById(currentItemId),
        "smooth",
        "start"
      );
    }
  }, [currentItemId]);

  return (
    <ScrollMenu
      apiRef={apiRef}
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      wrapperClassName={"scroll-menu"}
    >
      {children}
    </ScrollMenu>
  );
};
