import { Dividends, IRace, OperatorConfig } from '@matchem/matchem-common-ui'
import { useMediaQuery } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { useLocation } from 'hooks/location'
import { useSession } from 'hooks/session'
import { useBetinDividends } from 'hooks/useBetInDividends'
import { selectDisableBet } from 'reducers/config/configSlice'
import { selectMeetingsByRace } from 'reducers/meetings/meetingsSlice'
import { selectPage } from 'reducers/page/pageSlice'
import { openAffiliateLink } from 'utils/url'
import theme from '../../../style/theme'

interface Props {
    race: IRace
    operatorOdds: Record<string, OperatorConfig>
}

export const RaceDividends = ({ race, operatorOdds }: Props) => {
    const location = useLocation()
    const sessionId = useSession('betin_widget')
    const dividends = useBetinDividends(race.id)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const page = useAppSelector(selectPage)
    const disableBet = useAppSelector(selectDisableBet)
    const meeting = useAppSelector(selectMeetingsByRace(race?.meetingId!))

    if (!dividends) return null

    return (
        <Dividends
            race={race}
            dividends={dividends}
            onClickOperator={
                disableBet || !meeting
                    ? undefined
                    : (operator) =>
                          openAffiliateLink({
                              operator,
                              meeting,
                              race,
                              isMobile,
                              componentId: 'co',
                              page,
                              sid: sessionId,
                              country: location
                          })
            }
            operatorOdds={operatorOdds}
            mobileDisplay={isMobile}
        />
    )
}
