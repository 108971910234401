import { IBetinRaceOdd } from "@matchem/matchem-common-ui";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getBetinOddsAsync } from "reducers/betinOdds/thunks";

export const oddsAdapter = createEntityAdapter<IBetinRaceOdd>({
  selectId: (odd) => odd.raceId,
});

export const betinOddsSlice = createSlice({
  name: "betinOdds",
  initialState: oddsAdapter.getInitialState(),
  reducers: {
    setBetinRacesOdd(state, action: PayloadAction<IBetinRaceOdd>) {
      oddsAdapter.setOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBetinOddsAsync.fulfilled, (state, action) => {
      oddsAdapter.setMany(state, action.payload);
    });
  },
});

export const { setBetinRacesOdd } = betinOddsSlice.actions;
export const betinOddsReducers = betinOddsSlice.reducer;
