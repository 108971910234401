export const getCurrentPageId = (page: string) => {
    switch (page) {
        case 'home':
            return 'hp' // home page
        case 'race':
            return 'rp' // race page
        case 'horse':
            return 'cp' // cheval page
        default:
            return 'n/a'
    }
}
