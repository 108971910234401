import { BetInDividends } from "@matchem/matchem-common-ui";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { getBetInRaceDividendsAsync } from "./thunks";

export const betinDividendsAdapter = createEntityAdapter<BetInDividends>({
  selectId: (betInDividend) => betInDividend.raceId,
});

export const betinDividendsSlice = createSlice({
  name: "betinOdds",
  initialState: betinDividendsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getBetInRaceDividendsAsync.fulfilled,
      (state, { payload }) => {
        if (payload) {
          betinDividendsAdapter.setOne(state, payload);
        }
      }
    );
  },
});

export const betinDividendsReducers = betinDividendsSlice.reducer;
export type BetInDividendsState = EntityState<BetInDividends>;
