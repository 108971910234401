import { Button, ButtonGroup, styled } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { addDays, isTomorrow, isYesterday } from 'date-fns'
import { selectDate, updateDate } from 'reducers/date/dateSlice'
import { buildImgUrl } from 'utils/url'

type Day = 'addDay' | 'reduceDay'

export const PaginationDay = () => {
    const dispatch = useAppDispatch()
    const selectedDate = useAppSelector(selectDate)

    const onClickDate = (type: Day) => {
        let newDate = selectedDate
        if (type === 'addDay') {
            newDate = addDays(selectedDate, 1)
        } else if (type === 'reduceDay') {
            newDate = addDays(selectedDate, -1)
        }
        dispatch(updateDate(newDate))
    }

    return (
        <ButtonGroup size="small" disableElevation variant="contained">
            <StyledButton
                sx={{
                    borderTopLeftRadius: '18px',
                    borderBottomLeftRadius: '18px'
                }}
                onClick={() => onClickDate('reduceDay')}
                name="yesterday"
                disabled={isYesterday(selectedDate)}
            >
                {!isYesterday(selectedDate) && (
                    <ImgIcon
                        src={buildImgUrl('/img/arrow-left.png', 'widget')}
                        alt="yesterday-icon"
                    />
                )}
            </StyledButton>
            <StyledButton
                sx={{
                    borderTopRightRadius: '18px',
                    borderBottomRightRadius: '18px'
                }}
                onClick={() => onClickDate('addDay')}
                name="tomorrow"
                disabled={isTomorrow(selectedDate)}
            >
                {!isTomorrow(selectedDate) && (
                    <ImgIcon
                        src={buildImgUrl('/img/arrow-right.png', 'widget')}
                        alt="tomorrow-icon"
                    />
                )}
            </StyledButton>
        </ButtonGroup>
    )
}

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: '34px',
    borderRadius: 0,
    border: '0 !important',
    minWidth: '48px',

    '&:hover': {
        backgroundColor: theme.palette.grey[400]
    },

    '&:disabled': {
        backgroundColor: theme.palette.background.paper
    }
}))

const ImgIcon = styled('img')(() => ({
    width: '20px',
    height: '20px'
}))
