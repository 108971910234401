import { IRace } from '@matchem/matchem-common-ui'
import { Box, ListItem, styled, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { BetinComponent } from 'components/betin'
import { RacesSkeleton, TitleSkeleton } from 'components/races/RacesSkeleton'
import {
    differenceInMilliseconds,
    isToday,
    isTomorrow,
    isYesterday,
    parse
} from 'date-fns'
import { IMeeting } from 'models/meeting'
import { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { areEqual } from 'react-window'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'
import { selectDate } from 'reducers/date/dateSlice'
import { getCurrentDate } from 'utils/date'
import { connectMeetingsRaces } from 'utils/meeting-races'
import { isImminent, isOngoing, isUpcoming } from 'utils/race-status'
import { getLiveAndNextRaces, isItemClosed } from 'utils/races-categorization'
import theme from '../../style/theme'
import { ListCard, ListSubHeaderCard } from '../list/list'
import { RaceCardWrapper } from './RaceCardWrapper'

interface IRaceMeeting {
    race: IRace
    meeting: IMeeting
}

const ListTitle = ({
    title,
    isLoaded = true,
    gutterBottom = false
}: {
    title: string
    isLoaded?: boolean
    gutterBottom?: boolean
}): JSX.Element => {
    if (!isLoaded) return <TitleSkeleton />
    return (
        <ListSubHeaderCard disableGutters>
            <Typography variant={'h2'} gutterBottom={gutterBottom}>
                {title}
            </Typography>
        </ListSubHeaderCard>
    )
}

const RacesListTemplate = ({
    type,
    isLoaded = true,
    items,
    racesNumber,
    showBetBlock
}: {
    type: string
    isLoaded?: boolean
    items?: IRaceMeeting[]
    racesNumber?: number
    showBetBlock?: boolean
}): JSX.Element => (
    <DivRaceList>
        {showBetBlock && <BetinComponent component="block" sx={{ pt: 0 }} />}
        {!isLoaded ? (
            <RacesSkeleton racesNumber={racesNumber} />
        ) : items?.length ? (
            items.map(({ race, meeting }) => (
                <RenderRow
                    key={`${type}-${race.id + (race.name || '')}`}
                    race={race}
                    meeting={meeting}
                />
            ))
        ) : null}
    </DivRaceList>
)

const TitleAndRacesList = ({
    items,
    title,
    racesType,
    gutterBottom,
    racesNumber = 3,
    showBetBlock
}: {
    items?: IRaceMeeting[]
    title: string
    racesType: string
    gutterBottom?: boolean
    racesNumber?: number
    showBetBlock?: boolean
}): JSX.Element => {
    const selectedBetOperator = useAppSelector(selectBetOperator)

    return (
        <>
            <ListTitle
                title={title}
                gutterBottom={gutterBottom}
                isLoaded={Boolean(selectedBetOperator)}
            />
            <RacesListTemplate
                type={racesType}
                items={items}
                racesNumber={racesNumber}
                showBetBlock={showBetBlock}
                isLoaded={Boolean(selectedBetOperator)}
            />
        </>
    )
}

export const RaceList = ({
    races,
    meetings,
    showPastRaces
}: {
    races: IRace[]
    meetings: IMeeting[]
    showPastRaces: boolean
}): JSX.Element => {
    const { t } = useTranslation('common')
    const selectedDate = useAppSelector(selectDate)

    const date = getCurrentDate()
    const { closedItems, upcomingItems, ongoingItems } = connectMeetingsRaces(
        races,
        meetings
    ).reduce(
        (acc, item: IRaceMeeting) => {
            if (
                isItemClosed(item.race, item.meeting, date) &&
                (isToday(selectedDate) || isYesterday(selectedDate))
            )
                acc.closedItems.push(item)
            else if (isUpcoming(item.race.raceStatus))
                acc.upcomingItems.push(item)
            else if (
                isOngoing(item.race.raceStatus) ||
                isImminent(item.race.raceStatus)
            )
                acc.ongoingItems.push(item)
            return acc
        },
        {
            closedItems: [] as IRaceMeeting[],
            upcomingItems: [] as IRaceMeeting[],
            ongoingItems: [] as IRaceMeeting[]
        }
    )

    const liveAndNextRaces = getLiveAndNextRaces(
        ongoingItems,
        closedItems,
        upcomingItems
    )

    const closedItemsSoFar = useMemo(() => {
        if (closedItems.length >= 1 && upcomingItems.length >= 0) {
            return closedItems.slice(0, -1)
        }
        return closedItems
    }, [closedItems, upcomingItems])

    const isAnyClosedItem = closedItems.length > 0
    const isAnyUpcoming = upcomingItems.length > 0
    const isAnyOngoing = ongoingItems.length > 0
    const showClosedItemsSoFar =
        isAnyClosedItem && !isAnyUpcoming && !isAnyOngoing

    useEffect(() => {
        let timeoutId: NodeJS.Timeout
        if (isAnyUpcoming && upcomingItems[0].race.time) {
            const date = new Date()
            timeoutId = setTimeout(() => {
                closedItems.push(upcomingItems.shift() as IRaceMeeting)
            }, differenceInMilliseconds(parse(upcomingItems[0].race.time, 'HH:mm:ss', date), date) + 15 * 60 * 1000)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [closedItems, upcomingItems, isAnyUpcoming])

    return (
        <ListCard>
            {isAnyClosedItem ? (
                (isAnyUpcoming || isAnyOngoing) && (
                    <>
                        {showPastRaces && (
                            <TitleAndRacesList
                                title={t('race-list-results')}
                                items={closedItemsSoFar}
                                racesType="result"
                                gutterBottom
                            />
                        )}
                        <TitleAndRacesList
                            title={t('race-list-at-the-moment')}
                            items={liveAndNextRaces.liveItems}
                            racesType="now"
                        />
                        {isAnyUpcoming && liveAndNextRaces && (
                            <Box sx={{ mt: 3 }}>
                                <TitleAndRacesList
                                    title={t('race-list-next-races')}
                                    items={liveAndNextRaces.nextRaces}
                                    racesType="next"
                                    showBetBlock
                                />
                            </Box>
                        )}
                    </>
                )
            ) : (
                <TitleAndRacesList
                    title={t(
                        isTomorrow(selectedDate)
                            ? 'races'
                            : 'race-list-next-races'
                    )}
                    racesNumber={20}
                    items={liveAndNextRaces.nextDayRaces}
                    racesType="upcoming"
                    showBetBlock
                />
            )}

            {showClosedItemsSoFar && (
                <TitleAndRacesList
                    title={t('race-list-results')}
                    items={closedItems}
                    racesType="closed"
                    showBetBlock
                />
            )}
        </ListCard>
    )
}

const RenderRow = memo(({ race, meeting }: IRaceMeeting) => {
    return (
        <ListItem
            sx={{
                padding: 0,
                display: 'initial'
            }}
            disableGutters
        >
            <RaceCardWrapper race={race} meeting={meeting} />
        </ListItem>
    )
}, areEqual)

const DivRaceList = styled('div')(() => ({
    marginTop: theme.spacing(1)
}))
