export const isUpcoming = (raceStatus?: string): boolean =>
    ['PRG', ''].includes(raceStatus ?? '')

export const isImminent = (raceStatus?: string): boolean =>
    ['IMM', 'FXD'].includes(raceStatus ?? '')

export const isOngoing = (raceStatus?: string): boolean => raceStatus === 'EC'

export const isFinished = (
    raceStatus?: string,
    status?: string | null | undefined
): boolean =>
    ['AP', 'AD', 'PHO', 'APV', 'AN'].includes(raceStatus ?? '') ||
    ['V'].includes(status ?? '')

export const checkRaceStatus = (
    statusDef: string,
    raceStatus: string,
    status: string
): boolean => {
    if (statusDef === 'isFinished') return isFinished(raceStatus, status)
    if (statusDef === 'isNotFinished') {
        return (
            isFinished(raceStatus, status) === false &&
            (isUpcoming(raceStatus) ||
                isImminent(raceStatus) ||
                isOngoing(raceStatus))
        )
    }
    return false
}
