import {
    Bet,
    BetInDividends,
    BetinRace,
    IBetinRaceOdd,
    IRace,
    IRunner,
    IStatistics
} from '@matchem/matchem-common-ui'
import {
    Firestore,
    Query,
    collection,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    where
} from 'firebase/firestore'
import { Horse } from 'models/horse'
import { IMeeting } from 'models/meeting'
import { OperatorSharing } from 'models/operator-sharing'
import { PronosmartTips } from 'models/pronosmartTips'
import { dataFB } from './firebase'

export enum CollectionEnum {
    Meetings = 'meetings',
    Races = 'races',
    Runners = 'runners',
    Horses = 'horses',
    StatsRunners = 'statsRunners',
    PronosmartTips = 'pronosmartTips',
    BetinOdds = 'betinOdds',
    BetInRaces = 'betinRaces',
    BetInDividends = 'betinDividends',
    WidgetBetOperatorSharing = 'widgetBetOperatorSharing'
}

type FirestoreCollection = IMeeting | IRace | IRunner | IRunner[] | unknown
const dataDB = getFirestore(dataFB)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getCollection = <T extends FirestoreCollection>(
    collectionName: CollectionEnum,
    database: Firestore = dataDB
) => collection(database, collectionName)

const getFirstOrNull = async <T,>(q: Query): Promise<T | null> => {
    const querySnapshot = await getDocs(q)
    const result: any[] = []
    if (querySnapshot.empty) return null
    querySnapshot.forEach((doc) => result.push(doc.data()))
    return result[0]
}

const getAllOrEmpty = async <T,>(q: Query): Promise<T[]> => {
    const querySnapshot = await getDocs(q)
    const result: any[] = []
    if (querySnapshot.empty) return result
    querySnapshot.forEach((doc) => result.push(doc.data()))
    return result
}

export const getRunners = async (raceId: number): Promise<IRunner[]> => {
    const ref = getCollection<IRunner>(CollectionEnum.Runners)
    const q = query(ref, where('raceId', '==', raceId))
    const runners = await getAllOrEmpty<IRunner>(q)
    return runners.sort((a, b) => a.saddle - b.saddle)
}

export const getWinnerRunner = async (
    raceId: number
): Promise<IRunner | null> => {
    const ref = getCollection<IRunner>(CollectionEnum.Runners)
    const q = query(
        ref,
        where('raceId', '==', raceId),
        where('ranking', '==', 1)
    )
    return getFirstOrNull<IRunner>(q)
}

export const getWinnerRunners = async (
    meetingId: number
): Promise<IRunner[]> => {
    const ref = getCollection<IRunner>(CollectionEnum.Runners)
    const q = query(
        ref,
        where('meetingId', '==', meetingId),
        where('ranking', '==', 1)
    )
    return getAllOrEmpty<IRunner>(q)
}

export const fetchMeetings = (date: string): Query => {
    const ref = getCollection<IMeeting>(CollectionEnum.Meetings)
    return query(ref, where('date', '==', date), orderBy('pmuNumber'))
}

export const fetchRaces = (date: string): Promise<IRace[]> => {
    const ref = getCollection<IRace>(CollectionEnum.Races)
    const q = query(ref, where('date', '==', date), orderBy('time'))
    return getAllOrEmpty<IRace>(q)
}

export async function getMeeting(id: number): Promise<IMeeting | null> {
    const ref = getCollection<IMeeting>(CollectionEnum.Meetings)
    const q = query(ref, where('id', '==', id))
    return getFirstOrNull<IMeeting>(q)
}

export const getRace = async (id: number): Promise<IRace | null> => {
    const ref = getCollection<IRace>(CollectionEnum.Races)
    const q = query(ref, where('id', '==', id))
    return getFirstOrNull<IRace>(q)
}

export const getHorseById = (horseId: number): Promise<Horse | null> => {
    const ref = getCollection<Horse>(CollectionEnum.Horses)
    const q = query(ref, where('id', '==', horseId))

    return getFirstOrNull<Horse>(q)
}

export const getRunnersByHorseId = async (
    horseId: number,
    date: string
): Promise<IRunner[]> => {
    const ref = getCollection<IRunner>(CollectionEnum.Runners)
    const q = query(
        ref,
        where('horseId', '==', horseId),
        where('isRunnerState', '==', true),
        where('isRunning', '==', true),
        where('raceDate', '<=', date),
        orderBy('raceDate', 'desc')
    )
    return getAllOrEmpty<IRunner>(q)
}

export async function getPronosmartTipsByRaceId(
    raceId: number
): Promise<PronosmartTips | null> {
    const ref = getCollection<PronosmartTips>(CollectionEnum.PronosmartTips)
    const q = query(ref, where('raceId', '==', raceId))
    return getFirstOrNull<PronosmartTips>(q)
}

export const getStatsRunnersByRaceId = async (
    raceId: number
): Promise<IStatistics[] | null> => {
    const ref = getCollection<IStatistics>(CollectionEnum.StatsRunners)
    const q = query(ref, where('raceId', '==', raceId))
    return getAllOrEmpty<IStatistics>(q)
}

// Dividends
export async function getBetInRaceDividends(
    raceId: number
): Promise<BetInDividends | null> {
    const ref = getCollection<BetInDividends>(CollectionEnum.BetInDividends)
    const q = query(ref, where('raceId', '==', raceId))
    return getFirstOrNull<BetInDividends>(q)
}

export const getBetinRaceOdd = async (
    raceId: number
): Promise<IBetinRaceOdd | null> => {
    const ref = getCollection<IBetinRaceOdd>(CollectionEnum.BetinOdds)
    const q = query(ref, where('raceId', '==', raceId), limit(1))
    return await getFirstOrNull<IBetinRaceOdd>(q)
}

export const getBetinOddsByDate = async (
    date: string
): Promise<IBetinRaceOdd[]> => {
    const ref = getCollection<IRace>(CollectionEnum.BetinOdds)
    const q = query(
        ref,
        where('raceDateTime', '>=', date + 'T00:00:00.000Z'),
        where('raceDateTime', '<=', date + 'T23:59:60.999Z')
    )
    return await getAllOrEmpty<IBetinRaceOdd>(q)
}

export const getRacesBettingTypesByMeetingId = async (
    id: number
): Promise<BetinRace[]> => {
    const ref = getCollection<IRace>(CollectionEnum.BetInRaces)
    const q = query(ref, where('meetingId', '==', id))
    return await getAllOrEmpty<BetinRace>(q)
}

export const getRaceBettingTypesByRaceId = async (
    id: number
): Promise<Bet[] | null> => {
    const ref = getCollection<IRace>(CollectionEnum.BetInRaces)
    const q = query(ref, where('raceId', '==', id))
    const result = await getFirstOrNull<BetinRace>(q)
    return result ? result.bets : null
}

export const getBetOperatorSharing = async (
    clientKey: string
): Promise<OperatorSharing | null> => {
    const ref = getCollection<OperatorSharing>(
        CollectionEnum.WidgetBetOperatorSharing
    )
    const q = query(ref, where('id', '==', clientKey))
    return await getFirstOrNull<OperatorSharing>(q)
}
