import { IStatistics } from "@matchem/matchem-common-ui";
import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import theme from "style/theme";

const StatsCard = ({
  number,
  text,
  percentage,
}: {
  number: number;
  text: ReactNode;
  percentage?: boolean;
}) => {
  if (
    number === undefined ||
    number === null ||
    number < 0 ||
    Number.isNaN(number) ||
    (percentage && number <= 0)
  )
    return null;
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(0, 1, 0.5, 1),
        justifyContent: "center",
        borderRadius: "8px",
        borderWidth: "1.8px",
        borderColor: "grey.700",
        borderStyle: "solid",
        marginRight: 0.5,
        minWidth: "50px",
        background: percentage
          ? `linear-gradient(90deg, ${theme.palette.grey["200"]} ${number}%, transparent ${number}%)`
          : undefined,
      }}
    >
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "30px",
          fontSize: "20px",
          my: 0,
          mx: 0.3,
        }}
        variant="h3"
      >
        {percentage ? (number < 100 ? number : 100) + "%" : number}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "grey.500",
            lineHeight: "1",
            textAlign: "center",
          }}
          variant="subtitle2"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export const RunnerStats = ({ statsRunner }: { statsRunner: IStatistics }) => {
  const { t } = useTranslation("common");

  if (!statsRunner) return null;
  const percentageInFirstThreeRate = Math.round(
    statsRunner.statsHorse?.inFirst3Rate * 100
  );
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <StatsCard
        number={percentageInFirstThreeRate}
        text={t("percentageInFirstThreeRate")}
        percentage={true}
      />
      <StatsCard
        number={statsRunner.statsHorse.wins}
        text={
          <>
            1<sup>{t("ranking-1")}</sup>
          </>
        }
      />
      <StatsCard
        number={statsRunner.statsHorse.ranks2or3}
        text={
          <>
            2<sup>{t("ranking-2")}</sup> {t("or")} 3<sup>{t("ranking-3")}</sup>
          </>
        }
      />
      <StatsCard
        number={statsRunner.statsHorse.runs}
        text={t("races").toLowerCase()}
      />
    </Box>
  );
};
