import { IRace } from '@matchem/matchem-common-ui'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchRaces } from '../../services/firestore'

type Params = {
    date: string
    isClairefontaine?: boolean
    clairefontaineMeetingId?: number | null
}

export const getRacesByDateThunk = createAsyncThunk(
    'races/getRacesByDate',
    async ({ date, isClairefontaine, clairefontaineMeetingId }: Params) => {
        const races = await fetchRaces(date)
        if (isClairefontaine) {
            if (clairefontaineMeetingId) {
                const filteredRaces =
                    _filterRacesWithSameTimesThanRacesInAMeeting(
                        races,
                        clairefontaineMeetingId
                    )
                return { [date]: filteredRaces }
            }
            return { [date]: [] }
        }
        return { [date]: races }
    }
)

const _filterRacesWithSameTimesThanRacesInAMeeting = (
    races: IRace[],
    meetingId: number
): IRace[] => {
    const reverseRaces = [...races].reverse()
    const firstRace = races.find((race) => race.meetingId === meetingId)
    const lastRace = reverseRaces.find((race) => race.meetingId === meetingId)
    if (firstRace?.date && firstRace.time && lastRace?.date && lastRace.time) {
        const firstDateTime = new Date(`${firstRace.date} ${firstRace.time}`)
        const lastDateTime = new Date(`${lastRace.date} ${lastRace.time}`)
        const results = [...races].filter(({ date, time }) => {
            if (date && time) {
                const dateTime = new Date(`${date} ${time}`)
                return dateTime >= firstDateTime && dateTime <= lastDateTime
            }
            return false
        })
        return results
    }
    return races
}
