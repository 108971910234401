import { IRunner } from '@matchem/matchem-common-ui'
import Typography from '@mui/material/Typography'
import { Box, styled } from '@mui/material'
import Colours from 'components/common/Colours'
import { useTranslation } from 'react-i18next'
import { selectPronosmartTips } from 'reducers/pronosmartTips/pronosmartTipsSlice'
import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectRaceId } from 'reducers/race/raceSlice'
import { onRunnerClick } from 'utils/runner'

export const WinnersTips = ({ runners }: { runners: IRunner[] }) => {
    const { t } = useTranslation('common')
    const dispatch = useAppDispatch()
    const pronosmartTips = useAppSelector(selectPronosmartTips)
    const raceId = useAppSelector(selectRaceId)!
    const horseIdList = pronosmartTips?.tips.pronosmart?.horseIdList?.split(',')
    const runnersByTips = useMemo(() => {
        if (horseIdList && runners) {
            return runners
                .filter((runner) =>
                    horseIdList.includes(String(runner.horseId))
                )
                .sort((a, b) => {
                    return (
                        horseIdList.indexOf(String(a.horseId)) -
                        horseIdList.indexOf(String(b.horseId))
                    )
                })
                .splice(0, 5)
        } else {
            return null
        }
    }, [horseIdList, runners])

    if (!runnersByTips) return null
    return (
        <DivRoot>
            <Typography
                sx={{
                    mr: 1.5,
                    marginBottom: {
                        xs: 1,
                        sm: 0
                    }
                }}
                variant="h2"
            >
                {t('tips')}
            </Typography>
            <Box display="flex">
                {runnersByTips.map((runner) => (
                    <DivRunner
                        key={runner.id}
                        onClick={() => onRunnerClick(runner, raceId, dispatch)}
                    >
                        <Typography
                            sx={{ lineHeight: 1, mr: 0.5 }}
                            variant="h5"
                        >
                            {runner?.saddle}
                        </Typography>
                        <Colours
                            coloursSrc={runner.coloursPng}
                            width={18}
                            height={15}
                        />
                    </DivRunner>
                ))}
            </Box>
        </DivRoot>
    )
}

const DivRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'start'
    }
}))

const DivRunner = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1.2),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    borderRadius: 17,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.grey[400]
    }
}))
