import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { buildImgUrl } from "utils/url";
import { Box } from "@mui/material";

export const ShowPastRaces = ({
  showPastRaces,
  handleClick,
}: {
  showPastRaces: boolean;
  handleClick(): void;
}): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: (theme) => theme.spacing(0.5, 0, 1),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Typography variant={"subtitle2"} sx={{ mr: 1.375 }}>
          {showPastRaces ? t("show-at-the-moment-races") : t("show-past-races")}
        </Typography>
        <img
          alt={showPastRaces ? "arrow-down" : "arrow-up"}
          src={
            showPastRaces
              ? buildImgUrl("/img/common/arrow-down.svg")
              : buildImgUrl("/img/common/arrow-up.svg")
          }
          width={12}
          height={12}
        />
      </Box>
    </Box>
  );
};
