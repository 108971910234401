import { ReactNode, useContext, useEffect, useState } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { buildImgUrl } from "utils/url";
import { Button, styled } from "@mui/material";

const Arrow = ({
  children,
  disabled,
  onClick,
}: {
  children: ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) => {
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export const LeftArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <img
        src={buildImgUrl("/img/arrow-left.png", "widget")}
        alt="arrow-left-icon"
        width={24}
        height={24}
      />
    </Arrow>
  );
};

export const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <img
        src={buildImgUrl("/img/arrow-right.png", "widget")}
        alt="arrow-right-icon"
        width={24}
        height={24}
      />
    </Arrow>
  );
};

const StyledButton = styled(Button)(() => ({
  height: "36px",
  width: "36px",
  minWidth: "0 !important",

  "&:hover": {
    borderRadius: 15,
  },

  "&:disabled": {
    opacity: 0,
  },
}));
