import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { PronosmartTips } from "models/pronosmartTips";
import { ApiStatus } from "models/api";
import { RootState } from "../store";
import { getPronosmartTipsByRaceIdAsync } from "./pronosmartTipsThunks";

export interface pronosmartTipsState {
  value: PronosmartTips | undefined | null;
  status: ApiStatus;
}

const initialState: pronosmartTipsState = {
  value: undefined,
  status: "idle",
};

export const pronosmartTipsSlice = createSlice({
  name: "pronosmartTips",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPronosmartTipsByRaceIdAsync.fulfilled, (state, action) => {
        const pronosmartTips = action.payload;
        if (pronosmartTips) {
          state.value = pronosmartTips;
        }
      })
      .addMatcher(isPending(getPronosmartTipsByRaceIdAsync), (state) => {
        state.status = "loading";
      })
      .addMatcher(isRejected(getPronosmartTipsByRaceIdAsync), (state) => {
        state.status = "failed";
      })
      .addMatcher(isFulfilled(getPronosmartTipsByRaceIdAsync), (state) => {
        state.status = "idle";
      });
  },
});

export const selectPronosmartTips = (state: RootState) =>
  state.pronosmartTips.value;

export default pronosmartTipsSlice.reducer;
