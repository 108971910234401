import { Box, Link, styled, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import {
    selectClientKey,
    selectDisableLogoClickable
} from 'reducers/config/configSlice'
import { buildImgUrl } from 'utils/url'

export const Logo = ({ inlineMode }: { inlineMode?: boolean }) => {
    const disableClickable = useAppSelector(selectDisableLogoClickable)
    const clientKey = useAppSelector(selectClientKey)
    const logoContentStyle = {
        marginBottom: 1,
        marginRight: 2.1,
        display: inlineMode ? 'flex' : undefined,
        alignItems: inlineMode ? 'center' : undefined
    }
    const LogoImg = () => (
        <ImgStyled
            sx={{ ml: 0.5 }}
            src={buildImgUrl('/img/logo-ParisTurf.svg', 'widget')}
            alt="logo-ParisTurf"
        />
    )
    const LogoContent = () => (
        <Box sx={logoContentStyle}>
            <Typography variant={'h6'}>powered by</Typography>
            <LogoImg />
        </Box>
    )
    const LogoContentClickable = () => (
        <Link
            sx={logoContentStyle}
            underline="none"
            href={'https://www.paristurf.com?from=widget&client=' + clientKey}
            target="_blank"
        >
            <Typography variant={'h6'}>powered by</Typography>
            <LogoImg />
        </Link>
    )
    return disableClickable ? <LogoContent /> : <LogoContentClickable />
}

const ImgStyled = styled('img')(() => ({
    width: '72px',
    height: '9px'
}))
