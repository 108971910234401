import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type TPage = "home" | "race" | "runner" | "horse";
export interface PageState {
  value: TPage;
}

const initialState: PageState = {
  value: "home",
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    updatePage: (state, action: PayloadAction<PageState["value"]>) => {
      state.value = action.payload;
    },
  },
});

export const { updatePage } = pageSlice.actions;

export const selectPage = (state: RootState) => state.page.value;

export default pageSlice.reducer;
