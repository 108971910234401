import { IRunner } from '@matchem/matchem-common-ui'
import { updatePage } from 'reducers/page/pageSlice'
import { updateRunner } from 'reducers/runner/runnerSlice'
import { getHorseByIdAsync } from 'reducers/horse/horseThunks'
import { getStatsRunnersByRaceIdAsync } from 'reducers/runner/runnerThunks'
import type { AppDispatch } from 'reducers/store'

export const onRunnerClick = (
    runner: IRunner,
    raceId: number,
    dispatch: AppDispatch
) => {
    dispatch(updatePage('horse'))
    dispatch(getHorseByIdAsync(runner.horseId))
    dispatch(getStatsRunnersByRaceIdAsync(raceId))
    dispatch(updateRunner(runner))
}
