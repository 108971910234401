import { ThemeProvider } from '@mui/material/styles'
import theme from '../style/theme'
import { FunctionComponent, PropsWithChildren } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export const AppProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
    children
}) => {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {children}
            </LocalizationProvider>
        </ThemeProvider>
    )
}
