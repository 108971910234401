// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import { initializeApp } from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/performance";
import "firebase/functions";

// Web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const dataFirebaseConfig = {
  apiKey: process.env.REACT_APP_FB_DATA_API_KEY,
  authDomain: process.env.REACT_APP_FB_DATA_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATA_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_DATA_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_DATA_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_DATA_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_DATA_APP_ID,
  measurementId: process.env.REACT_APP_FB_DATA_MEASUREMENT_ID,
};

// Initialize Firebase
export const dataFB = initializeApp(dataFirebaseConfig);
