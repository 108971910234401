import { Box, styled, Typography } from '@mui/material'
import {
    IRace,
    IRunner,
    isFinished,
    tableHelpers
} from '@matchem/matchem-common-ui'
import { useMemo } from 'react'
import { updateRunner } from 'reducers/runner/runnerSlice'
import Colours from 'components/common/Colours'
import { useAppDispatch } from 'app/hooks'
import { getHorseByIdAsync } from 'reducers/horse/horseThunks'
import { HorizontalSelector } from '../horizontalSelector/HorizontalSelector'

const OperatorCard = ({
    runner,
    itemId,
    currentRunner
}: {
    disabled?: boolean
    runner: IRunner
    itemId: string
    currentRunner: IRunner
}): JSX.Element => {
    const dispatch = useAppDispatch()

    return (
        <DivRunner
            data-testid={itemId}
            className={runner.id === currentRunner!.id ? 'selected' : undefined}
            onClick={() => {
                dispatch(updateRunner(runner))
                dispatch(getHorseByIdAsync(runner.horseId))
            }}
        >
            <Typography variant="h5">{runner?.saddle}</Typography>
            <Box sx={{ marginInline: 0.5 }}>
                <Colours
                    coloursSrc={runner.coloursPng}
                    width={17}
                    height={14}
                />
            </Box>
            <Typography variant="subtitle2" style={{ width: 'max-content' }}>
                {runner.horseName}
            </Typography>
        </DivRunner>
    )
}

export const RunnerSelector = ({
    runners,
    currentRunner,
    race
}: {
    runners: IRunner[]
    currentRunner: IRunner
    race: IRace
}) => {
    const reorderedRunners = useMemo(() => {
        return isFinished(race.raceStatus, race.status)
            ? runners.sort(tableHelpers.getComparator('asc', 'ranking'))
            : runners
    }, [race.raceStatus, runners, race.status])

    return (
        <DivContainer>
            <HorizontalSelector currentItemId={currentRunner.id}>
                {reorderedRunners.map((runner) => (
                    <OperatorCard
                        key={runner.id}
                        runner={runner}
                        currentRunner={currentRunner}
                        itemId={runner.id}
                    />
                ))}
            </HorizontalSelector>
        </DivContainer>
    )
}

const DivContainer = styled('div')(({ theme }) => ({
    paddingInline: theme.spacing(0.2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '18px',
    alignItems: 'center',
    marginBottom: theme.spacing(2),

    '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
        display: 'none'
    },

    '& .react-horizontal-scrolling-menu--scroll-container': {
        'MsOverflowStyle:': 'none',
        scrollbarWidth: 'none'
    }
}))

const DivRunner = styled('div')(({ theme }) => ({
    lineHeight: 0,
    padding: theme.spacing(1),
    margin: theme.spacing(0.25, 0),
    alignItems: 'center',
    borderRadius: 15,
    display: 'flex',

    '&.selected': {
        cursor: 'default',
        backgroundColor: 'white',
        boxShadow: '0.5px 0.5px 2px 0px #c8c8c8'
    },

    '&:hover': {
        cursor: 'pointer'
    }
}))
