import { Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { selectMeetingsByRace } from 'reducers/meetings/meetingsSlice'
import {
    selectIsQueryPending,
    selectQuinteRace
} from 'reducers/races/racesSlice'
import { RaceCardWrapper } from './RaceCardWrapper'
import { useTranslation } from 'react-i18next'
import { TitleAndRacesSkeleton } from 'components/races/RacesSkeleton'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'

export const QuinteItem = () => {
    const race = useAppSelector(selectQuinteRace)
    const selectedBetOperator = useAppSelector(selectBetOperator)
    const meeting = useAppSelector(selectMeetingsByRace(race?.meetingId))
    const isRacesLoading = useAppSelector(selectIsQueryPending)
    const { t } = useTranslation('common')

    if (isRacesLoading || !selectedBetOperator) return <TitleAndRacesSkeleton />
    return (
        <>
            {race && meeting && (
                <>
                    <Typography variant={'h2'} gutterBottom>
                        {t('quinte-du-jour')}
                    </Typography>
                    <RaceCardWrapper race={race} meeting={meeting} />
                </>
            )}
        </>
    )
}
