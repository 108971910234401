import { v4 as uuidV4 } from "uuid";

export const useSession = (key: string) => {
  const value = window.sessionStorage.getItem(key)!;
  if (!value) {
    const newValue = uuidV4();
    window.sessionStorage.setItem(key, newValue);
    return newValue;
  }
  return value;
};
