import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#212227",
    },
    secondary: {
      main: "#E42313",
    },
    error: {
      main: red.A400,
      dark: "#A11A0F",
    },
    success: {
      light: "#007831",
      dark: "#02692D",
      main: "#2D9766",
    },
    divider: "#C2C2CA",
    text: {
      primary: "#212227",
      disabled: "#52525D",
    },
    background: {
      default: "#FFFFFF",
      paper: "#EEF0F4",
    },
    grey: {
      50: "#EEEEF4",
      100: "rgba(140, 151, 208, 0.1)",
      200: "#EEF0F4",
      300: "#D8D8D8",
      400: "#D5D8DE",
      500: "#92929D",
      700: "#c3c3cb",
      800: "#F2F4F7",
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    button: {
      borderRadius: "2px",
    },
    h1: {
      fontWeight: 800,
      fontSize: 36,
    },
    h2: {
      fontWeight: 400,
      fontSize: 18,
    },
    h3: {
      fontWeight: 800,
      fontSize: 18,
    },
    h4: {
      fontWeight: 400,
      fontSize: 14,
    },
    h5: {
      fontWeight: 700,
      fontSize: 12,
    },
    h6: {
      fontWeight: 400,
      fontSize: 10,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 12,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 10,
    },
    caption: {
      fontWeight: 400,
      fontSize: 14,
      fontStyle: "italic",
    },
    overline: {
      fontWeight: 700,
      fontSize: 12,
    },
    fontSize: 14,
    fontWeightBold: 800,
  },
});

export default theme;
