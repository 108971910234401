import {
    IMeeting,
    IRace,
    isImminent,
    isUpcoming,
    RaceTimer
} from '@matchem/matchem-common-ui'
import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { selectClientKey } from 'reducers/config/configSlice'
import { diffTimes } from 'utils/date'
import { buildRaceUrl } from 'utils/url'
import theme from '../../style/theme'

export const RaceName = ({
    race,
    meeting
}: {
    race: IRace
    meeting: IMeeting
}) => {
    const { t } = useTranslation('common')
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const upcoming =
        isUpcoming(race?.raceStatus) || isImminent(race?.raceStatus)
    const minutesToStart = upcoming
        ? diffTimes(new Date(`${race.date} ${race.time}`))
        : 0
    const clientKey = useAppSelector(selectClientKey)
    const href = `${buildRaceUrl(
        race,
        meeting
    )}?from=widget&client=${clientKey}`
    return (
        <Box
            sx={{
                marginLeft: 1,
                marginBottom: 1,
                width: '100%'
            }}
        >
            {!isMobile ? (
                <DivWrapper>
                    <Grid item sm={9}>
                        <Link href={href} target="_blank">
                            <Typography variant="h3">
                                {meeting.pmuNumber && meeting.pmuNumber > 0
                                    ? `R${meeting.pmuNumber}`
                                    : 'PM'}
                            </Typography>
                            <Typography variant="h3" sx={{ ml: 0.5 }}>
                                {`C${race.number}`}
                            </Typography>
                            <TypographyName
                                variant="h3"
                                sx={{
                                    textTransform: 'uppercase',
                                    overflow: {
                                        sm: 'visible'
                                    }
                                }}
                            >
                                {meeting.name}
                            </TypographyName>
                            <TypographyName variant="h2">
                                {race.name}
                            </TypographyName>
                            {race.isTQQ && (
                                <DivQuinte>{t('label-quinte')}</DivQuinte>
                            )}
                        </Link>
                    </Grid>
                    <Grid
                        item
                        sm={3}
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center',
                            marginRight: 1
                        }}
                    >
                        <RaceTimer
                            minutesToStart={minutesToStart}
                            race={race as any}
                            bigger
                            dark
                        />
                    </Grid>
                </DivWrapper>
            ) : (
                <Grid container direction="row">
                    <Grid container item xs={8} direction="row">
                        <Grid item style={{ display: 'flex' }}>
                            <Link href={href} target="_blank">
                                <TypographyNumber variant="h3">
                                    {meeting.pmuNumber && meeting.pmuNumber > 0
                                        ? `R${meeting.pmuNumber}`
                                        : 'PM'}
                                </TypographyNumber>
                                <TypographyName
                                    variant="h3"
                                    sx={[
                                        {
                                            textTransform: 'uppercase',
                                            overflow: {
                                                sm: 'visible'
                                            }
                                        },
                                        meeting.pmuNumber === 0 && {
                                            ml: 1
                                        },
                                        race.number! > 9 &&
                                            meeting.pmuNumber === 0 && {
                                                ml: 2.4
                                            },
                                        race.number! > 9 &&
                                            meeting.pmuNumber! > 0 && {
                                                ml: 2.775
                                            }
                                    ]}
                                >
                                    {meeting.name}
                                </TypographyName>
                            </Link>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                sx={{
                                    display: 'flex',
                                    overflow: 'hidden',
                                    alignItems: 'center',
                                    marginTop: theme.spacing(0.5)
                                }}
                                item
                                xs={12}
                            >
                                <TypographyNumber variant="h2">
                                    {`C${race.number}`}
                                    <Box
                                        sx={{
                                            marginLeft: {
                                                xs: 1.5,
                                                sm: 1
                                            }
                                        }}
                                    >
                                        {race.name}
                                    </Box>
                                </TypographyNumber>
                                {race.isTQQ && (
                                    <DivQuinte>{t('label-quinte')}</DivQuinte>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            marginRight: 1,
                            alignItems: 'center',
                            height: 'min-content'
                        }}
                    >
                        <RaceTimer
                            minutesToStart={minutesToStart}
                            race={race as any}
                            bigger
                            dark
                            mobileDisplay
                        />
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

const Link = styled('a')(() => ({
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'baseline',

    '&:hover': {
        textDecoration: 'underline'
    }
}))

const DivWrapper = styled('div')(() => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center'
}))

const TypographyName = styled(Typography)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1)
    }
}))

const DivQuinte = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    background: theme.palette.secondary.main,
    fontSize: '10px',
    padding: theme.spacing(0, 0.375),
    margin: theme.spacing(0, 0.75),
    fontWeight: 800,
    textTransform: 'uppercase',
    height: 'fit-content',
    alignSelf: 'center'
}))

const TypographyNumber = styled(Typography)(({ theme }) => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center'
}))
