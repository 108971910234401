import {
    IRace,
    IRunner,
    RaceTableWithDropdown,
    RunnersTable,
    TDiscipline
} from '@matchem/matchem-common-ui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'
import { selectWithScroll } from 'reducers/config/configSlice'
import { selectFormattedDate } from 'reducers/date/dateSlice'
import { getHorsePastRacesDataAsync } from 'reducers/horse/horseThunks'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    selectHorse,
    selectHorsePastRaces,
    selectHorseRunnersInPastRace,
    selectHorseRunnersList
} from 'reducers/horse/horseSlice'
import { selectMeetingsByRace } from 'reducers/meetings/meetingsSlice'
import { selectRaceId } from 'reducers/race/raceSlice'
import { selectRaceById } from 'reducers/races/racesSlice'
import {
    selectRunner,
    selectRunnersByRaceID,
    selectStatsRunnerByRunnerId
} from 'reducers/runner/runnerSlice'
import { HorseInfo } from './HorseInfo'
import { useBetinRaceOdds } from 'hooks/useBetinRaceOdds'

export const HorseDetail = () => {
    const { t } = useTranslation('common')
    const dispatch = useAppDispatch()
    const pastRaces = useAppSelector(selectHorsePastRaces)
    const runnersTable = useAppSelector(selectHorseRunnersList)
    const horse = useAppSelector(selectHorse)!
    const raceId = useAppSelector(selectRaceId)!
    const runner = useAppSelector(selectRunner)!
    const race = useAppSelector(selectRaceById(raceId))!
    const meeting = useAppSelector(selectMeetingsByRace(race.meetingId))
    const runners = useAppSelector(selectRunnersByRaceID(raceId))!
    const selectedDate = useAppSelector(selectFormattedDate)
    const statsRunners = useAppSelector(selectStatsRunnerByRunnerId(runner.id))!
    const withScroll = useAppSelector(selectWithScroll)

    useEffect(() => {
        runner &&
            dispatch(
                getHorsePastRacesDataAsync({
                    horse,
                    runner,
                    date: race.date || selectedDate
                })
            )
    }, [dispatch, runner, horse, race.date, selectedDate])

    if (!meeting || !race || !runner || !horse) return null

    return (
        <>
            <HorseInfo
                race={race}
                runner={runner}
                meeting={meeting}
                horse={horse}
                runners={runners}
                statsRunner={statsRunners}
            />
            <RaceTableWithDropdown
                title={t('horse-last-races')}
                pastRaces={pastRaces || []}
                runners={runnersTable || []}
                discipline={race.discipline as TDiscipline}
                resultsPerPage={withScroll ? 10 : undefined}
                detailPanel={({ race }) => (
                    <DetailPanel race={race} runners={runners} />
                )}
                orderByColumn="date"
                orderDirection="desc"
            />
        </>
    )
}

const DetailPanel = ({
    race,
    runners
}: {
    race: IRace
    runners: IRunner[]
}) => {
    const betOperator = useAppSelector(selectBetOperator)
    const runnersInPastRaces = useAppSelector(selectHorseRunnersInPastRace)
    const runner = useAppSelector(selectRunner)!
    const betinRaceOdd = useBetinRaceOdds(race.id)

    const runnersInPastRace =
        runnersInPastRaces?.find((e) => e.index === race.id)?.runners ?? []
    const pastRaceRunner = runnersInPastRace.find(
        (r) => r.horseName === runner.horseName
    )

    if (!pastRaceRunner || !race.specialty || !betOperator) {
        return null
    }

    return (
        <RunnersTable
            runner={pastRaceRunner}
            runners={runnersInPastRace}
            contextRunners={runners}
            specialty={race.specialty}
            autostart={race.autostart}
            betOperator={betOperator}
            highlightHorseName={true}
            betinRaceOdd={
                betinRaceOdd ??
                ({
                    odds: []
                } as any)
            }
        />
    )
}
