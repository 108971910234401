import { useAppSelector } from 'app/hooks'
import {
    selectPMHMeetings,
    selectPremiumMeetings
} from 'reducers/meetings/meetingsSlice'
import { selectRaces } from 'reducers/races/racesSlice'
import { Container } from '../common/Container'
import { QuinteItem } from '../races/QuinteItem'
import { MeetingsList } from './MeetingsList'

export const Meetings = () => {
    const premiumMeetings = useAppSelector(selectPremiumMeetings)
    const pmhMeetings = useAppSelector(selectPMHMeetings)
    const races = useAppSelector(selectRaces)

    return (
        <Container>
            <QuinteItem />
            <MeetingsList
                races={races}
                premiumMeetings={premiumMeetings}
                pmhMeetings={pmhMeetings}
            />
        </Container>
    )
}
