import { useEffect, useState } from 'react'
import { OperatorSharing } from 'models/operator-sharing'
import { getBetOperatorSharing } from 'services/firestore'
import { useAppSelector } from 'app/hooks'
import {
    selectOperatorOdds,
    selectClientKey
} from 'reducers/config/configSlice'

export const useOperators = () => {
    const operatorOdds = useAppSelector(selectOperatorOdds)
    const clientKey = useAppSelector(selectClientKey)
    const [operatorSharing, setOperatorSharing] = useState(
        {} as OperatorSharing | null
    )
    useEffect(() => {
        ;(async () =>
            clientKey &&
            setOperatorSharing(await getBetOperatorSharing(clientKey)))()
    }, [clientKey])

    let selectedBetOperator = ''
    let randomNumber = Math.random()
    const operators = Object.keys(operatorOdds || {})
    for (const [key, value] of Object.entries(operatorSharing || {})) {
        if (key !== 'id') {
            if (value > randomNumber && operators.includes(key)) {
                selectedBetOperator = key
                break
            }
            randomNumber -= value
        }
    }
    return {
        operators,
        selectedBetOperator
    }
}
