import { useAppDispatch, useAppSelector } from "app/hooks";
import { getBetinRaceOdd } from "services/firestore";
import { setBetinRacesOdd } from "reducers/betinOdds/slice";
import { useEffect } from "react";
import { selectBetinRaceOdds } from "reducers/betinOdds/selectors";

export const useBetinRaceOdds = (raceId: number | undefined) => {
  const betinRaceOdds = useAppSelector(selectBetinRaceOdds(raceId));
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (raceId) {
      getBetinRaceOdd(raceId).then((resp) => {
        if (resp) {
          dispatch(setBetinRacesOdd(resp));
        }
      });
    }
  }, [dispatch, raceId]);

  return betinRaceOdds;
};
