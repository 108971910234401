import { IPastRace, IRace, IRunner } from "@matchem/matchem-common-ui";

const getSurfaceDisplayLabel = (race: IRace): string | undefined =>
  race.discipline !== "Galop" && race.surface !== "PH"
    ? race.surface
    : !race.liveGoing
    ? race.going || "-"
    : race.liveGoing;

export const getPastRace = (race: IRace, runner: IRunner): IPastRace => {
  return {
    ...race,
    distance: runner.distance || race.distance,
    ranking: runner.incident || runner.liveIncident || runner.ranking,
    handicapRatingKg: runner.handicapRatingKg,
    redkm: runner.redkm,
    shoeing: runner.shoeing,
    blinkers: runner.blinkers,
    surfaceOrGoing: getSurfaceDisplayLabel(race),
    horseName: runner.horseName,
    trainerName: runner.trainerName,
    jockeyName: runner.jockeyName,
    runnerChrono: runner.chrono,
  };
};
