import {
    Bet,
    IRace,
    MeetingCard,
    TBetOperator,
    TBetinRunnerOdds,
    TBetinRunnersOdds,
    getBetinRunnersOdds,
    getFavoriteRunnerId,
    isFinished
} from '@matchem/matchem-common-ui'
import {
    ListItem,
    SxProps,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BetinComponent } from 'components/betin'
import { useLocation } from 'hooks/location'
import { useSession } from 'hooks/session'
import { IMeeting } from 'models/meeting'
import React, {
    Fragment,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { areEqual } from 'react-window'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'
import { selectBetinRacesOddsEntities } from 'reducers/betinOdds/selectors'
import { selectDisableBet } from 'reducers/config/configSlice'
import { selectPage, updatePage } from 'reducers/page/pageSlice'
import { getPronosmartTipsByRaceIdAsync } from 'reducers/pronosmartTips/pronosmartTipsThunks'
import { updateRaceId } from 'reducers/race/raceSlice'
import { selectWinnerRunnersFromMeeting } from 'reducers/runner/runnerSlice'
import {
    getWinnerRunnerAsync,
    getWinnerRunnersAsync
} from 'reducers/runner/runnerThunks'
import { getRacesBettingTypesByMeetingId } from 'services/firestore'
import { getAffiliateLink } from 'utils/url'
import theme from '../../style/theme'
import { ListCard, ListSubHeaderCard } from '../list/list'

export const MeetingsList = ({
    races,
    premiumMeetings,
    pmhMeetings
}: {
    races: IRace[]
    premiumMeetings: IMeeting[]
    pmhMeetings: IMeeting[]
}): JSX.Element => {
    const { t } = useTranslation('common')
    const selectedBetOperator = useAppSelector(selectBetOperator)

    return (
        <ListCard>
            {premiumMeetings.length > 0 && (
                <ListSubHeaderCard disableGutters>
                    <Typography variant={'h2'} sx={{ mt: 2 }}>
                        {t('meetings-list-premium')}
                    </Typography>
                </ListSubHeaderCard>
            )}
            {premiumMeetings.length > 0 &&
                selectedBetOperator &&
                premiumMeetings.map((meeting) => (
                    <Fragment key={`meeting-premium-${meeting.id}`}>
                        <RenderRow
                            races={races.filter(
                                ({ meetingId }) => meetingId === meeting.id
                            )}
                            meeting={meeting}
                            betOperator={selectedBetOperator}
                            sx={{
                                padding: 0,
                                display: 'initial'
                            }}
                        />
                    </Fragment>
                ))}

            {pmhMeetings.length > 0 && (
                <ListSubHeaderCard disableGutters>
                    <Typography variant={'h2'}>
                        {t('meetings-list-pmh')}
                    </Typography>
                </ListSubHeaderCard>
            )}
            {pmhMeetings.length > 0 &&
                selectedBetOperator &&
                pmhMeetings.map((meeting) => (
                    <RenderRow
                        key={`meeting-pmh-${meeting.id}`}
                        races={races.filter(
                            ({ meetingId }) => meetingId === meeting.id
                        )}
                        meeting={meeting}
                        betOperator={selectedBetOperator}
                        sx={{
                            padding: 0,
                            display: 'initial'
                        }}
                    />
                ))}
        </ListCard>
    )
}

const RenderRow = memo(
    ({
        races,
        meeting,
        betOperator,
        sx = []
    }: {
        races: IRace[]
        meeting: IMeeting
        betOperator: TBetOperator
        sx?: SxProps<Theme>
    }) => {
        const betinRacesOdds = useSelector(selectBetinRacesOddsEntities)
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
        const [bets, setBets] = useState<Record<string, Bet[]>>({})
        const dispatch = useAppDispatch()
        const page = useAppSelector(selectPage)
        const disableBet = useAppSelector(selectDisableBet)
        const sid = useSession('betin_widget')
        const country = useLocation()
        const winnerMeetingRunners = useAppSelector(
            selectWinnerRunnersFromMeeting(meeting.id!)
        )

        useEffect(() => {
            let removed = false

            const getBettingTypes = async () => {
                const resp = await getRacesBettingTypesByMeetingId?.(
                    meeting.id!
                )

                const betTypes = resp?.reduce(
                    (bets, elem) => ({
                        ...bets,
                        [elem.raceId]: elem.bets
                    }),
                    {}
                )
                if (!removed) {
                    setBets(betTypes)
                }
            }
            if (meeting.id) {
                void getBettingTypes()
            }

            return () => {
                removed = true
            }
        }, [meeting.id])

        const favoriteRunnersIds: Record<string, string> = useMemo(() => {
            if (!races || !betinRacesOdds) return {}
            return (
                races.reduce<Record<string, string>>((acc, race) => {
                    if (betinRacesOdds[race.id]) {
                        acc[race.id] =
                            getFavoriteRunnerId(
                                betinRacesOdds[race.id]!,
                                betOperator
                            ) || ''
                    }
                    return acc
                }, {}) || {}
            )
        }, [betinRacesOdds, races, betOperator])

        const betinFavoriteRunnersOdds: TBetinRunnersOdds | undefined =
            useMemo(() => {
                const result: Record<string, any> = {}
                if (races && betinRacesOdds) {
                    races.forEach((race) => {
                        if (betinRacesOdds[race.id]) {
                            const betinRunnersOdds: TBetinRunnersOdds =
                                getBetinRunnersOdds(betinRacesOdds[race.id]!)
                            const favoriteRunnerId = getFavoriteRunnerId(
                                betinRacesOdds[race.id]!,
                                betOperator
                            )
                            if (
                                favoriteRunnerId &&
                                betinRunnersOdds[favoriteRunnerId]
                            ) {
                                result[race.id] =
                                    betinRunnersOdds[favoriteRunnerId]
                            }
                        }
                    })
                    return result
                }
                return undefined
            }, [betinRacesOdds, races, betOperator])

        const operatorOddLinks = useMemo(() => {
            if (disableBet || !races.length || !meeting || !betOperator)
                return {}
            return (
                races.reduce<Record<string, string>>((acc, race) => {
                    const betinRaceOdds = betinRacesOdds[race.id]
                    const runnerId = favoriteRunnersIds?.[race.id]
                    const betinRaceOdd = betinRaceOdds?.odds.find(
                        ({ operator }) => operator === betOperator
                    )
                    const runnerOdd = betinRaceOdd?.runners.find(
                        ({ runnerId: betinRaceRunnerId }) =>
                            betinRaceRunnerId === runnerId
                    )
                    acc[race.id] = getAffiliateLink({
                        operator: betOperator,
                        meeting,
                        race,
                        runnerOdd,
                        isMobile,
                        componentId: 'co',
                        page,
                        sid,
                        country
                    })
                    return acc
                }, {}) || {}
            )
        }, [
            betinRacesOdds,
            favoriteRunnersIds,
            country,
            races,
            betOperator,
            meeting,
            isMobile,
            page,
            sid,
            disableBet
        ])

        useEffect(() => {
            meeting.id && dispatch(getWinnerRunnersAsync(meeting.id))
        }, [dispatch, meeting.id])

        const winnerRunners = useMemo(() => {
            if (winnerMeetingRunners && betinRacesOdds) {
                const result: Record<
                    string,
                    { horseName: string; odd: number }
                > = {}
                winnerMeetingRunners.forEach((runner) => {
                    const betinRunnersOdds = betinRacesOdds[runner.raceId]
                        ? getBetinRunnersOdds(betinRacesOdds[runner.raceId]!)
                        : undefined

                    const betinRunnerOdd = betinRunnersOdds?.[runner.id]
                    if (betinRunnerOdd) {
                        result[runner.raceId] = {
                            horseName: runner.horseName,
                            odd: betinRunnerOdd[betOperator].liveOdd
                        }
                    }
                })
                return result
            }
            return undefined
        }, [betOperator, betinRacesOdds, winnerMeetingRunners])

        const openRaceDetail = useCallback(
            (race: IRace) => {
                dispatch(updatePage('race'))
                dispatch(updateRaceId(race.id))
                isFinished(race.raceStatus, race.status)
                    ? dispatch(getWinnerRunnerAsync(race.id))
                    : dispatch(getPronosmartTipsByRaceIdAsync(race.id))
            },
            [dispatch]
        )

        const onOperatorOddClick = useCallback(
            (betinRunnerOdd: TBetinRunnerOdds) => {
                const raceId =
                    betinRunnerOdd[betOperator]?.runnerId.split('-')[0]
                if (!disableBet && betinRacesOdds && betinRacesOdds[raceId]) {
                    const operatorLink = operatorOddLinks[raceId]
                    if (operatorLink) {
                        window.open(operatorLink)
                    }
                }
            },
            [disableBet, betOperator, betinRacesOdds, operatorOddLinks]
        )

        const onClickBetTypes = useCallback(
            (
                raceId: number,
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
            ) => {
                const operatorOddLink = operatorOddLinks
                    ? operatorOddLinks[raceId]
                    : undefined
                if (operatorOddLink) window.open(operatorOddLink, '_blank')
                event.stopPropagation()
            },
            [operatorOddLinks]
        )

        return (
            <ListItem sx={[...(Array.isArray(sx) ? sx : [sx])]} disableGutters>
                {!isMobile && races.length > 0 && (
                    <BetinComponent
                        component="block"
                        options={{
                            context: {
                                meetingNumber: meeting.pmuNumber
                            },
                            expandedFirst: true,
                            translation: {
                                title: meeting.name || ''
                            }
                        }}
                    />
                )}
                <MeetingCard
                    betOperator={betOperator}
                    meeting={meeting}
                    operatorOddLinks={operatorOddLinks as any}
                    races={races}
                    bets={bets}
                    winnerRunners={winnerRunners}
                    mobileDisplay={isMobile}
                    onClickMeetingNumber={openRaceDetail}
                    onClickCard={openRaceDetail}
                    clickableOdd={!disableBet}
                    inlineMode={false}
                    onClickOdd={onOperatorOddClick as any}
                    onClickBetTypes={onClickBetTypes}
                    betinFavoriteRunnersOdds={betinFavoriteRunnersOdds}
                    theming={{
                        meetingNumber: { backgroundColor: '#000' },
                        number: { backgroundColor: '#FFF' }
                    }}
                />
            </ListItem>
        )
    },
    areEqual
)
