import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";

void i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "fr",
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
  },
});

export default i18n;
