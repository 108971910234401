import { useMediaQuery } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { Dispatch, SetStateAction } from 'react'
import { selectPage } from 'reducers/page/pageSlice'
import theme from '../../style/theme'
import { BackButton } from '../common/BackButton'
import { HomeButton } from '../common/HomeButton'
import { OperatorSelector } from '../common/OperatorSelector'
import { RaceSelector } from '../races/RaceSelector'
import styles from './Header.module.css'
import { Logo } from './Logo'
import { PaginationDay } from './PaginationDay'
import { SelectedDate } from './SelectedDate'
import { ToggleButtons } from './ToggleButtons'

export const Header = ({
    racesOrMeetings,
    setRacesOrMeetings
}: {
    racesOrMeetings: 'races' | 'meetings'
    setRacesOrMeetings: Dispatch<SetStateAction<'races' | 'meetings'>>
}) => {
    const currentPage = useAppSelector(selectPage)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            {currentPage === 'home' && (
                <>
                    <style>
                        {`
                        @container (max-width: 400px) {
                            .${styles.homeSwitchDay} {
                                width: 100%;
                            }
                        }
                        `}
                    </style>
                    <div className={styles.homeRow1}>
                        <SelectedDate />
                        <Logo />
                    </div>
                    <div className={styles.homeRow2}>
                        <div className={styles.homeSwitchDay}>
                            <PaginationDay />
                        </div>
                        <ToggleButtons
                            racesOrMeetings={racesOrMeetings}
                            setRacesOrMeetings={setRacesOrMeetings}
                        />
                        <OperatorSelector />
                    </div>
                </>
            )}
            {(currentPage === 'race' || currentPage === 'horse') && (
                <>
                    <div className={styles.pageRow1}>
                        <BackButton />
                        <HomeButton />
                        <SelectedDate />
                        {isMobile && currentPage !== 'horse' && (
                            <OperatorSelector />
                        )}
                    </div>
                    <div className={styles.pageRow2}>
                        <RaceSelector />
                        {!isMobile && currentPage !== 'horse' && (
                            <OperatorSelector />
                        )}
                    </div>
                </>
            )}
        </>
    )
}
