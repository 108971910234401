import { RootState } from "../store";
import { oddsAdapter } from "./slice";

export const oddsSelectors = oddsAdapter.getSelectors<RootState>(
  (state) => state.betinOdds
);

export const selectBetinRacesOddsEntities = oddsSelectors.selectEntities;

export const selectBetinRaceOdds =
  (id: number | undefined) => (state: RootState) => {
    if (typeof id === "undefined") return undefined;
    return oddsSelectors.selectById(state, id);
  };
