import {
    OperatorSelector as LibOperatorSelector,
    TBetOperator
} from '@matchem/matchem-common-ui'
import { Skeleton } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    selectBetOperator,
    updateBetOperator
} from 'reducers/betOperator/betOperatorSlice'
import { selectOperatorOdds } from 'reducers/config/configSlice'

export const OperatorSelector = () => {
    const dispatch = useAppDispatch()
    const selectedBetOperator = useAppSelector(selectBetOperator)
    const operatorOdds = useAppSelector(selectOperatorOdds)

    if (
        !selectedBetOperator ||
        !operatorOdds ||
        !Object.keys(operatorOdds).includes(selectedBetOperator)
    )
        return (
            <Skeleton
                variant="rounded"
                width={150}
                height={38}
                sx={{ marginLeft: 'auto' }}
            />
        )

    return (
        <LibOperatorSelector
            currentBetOperator={selectedBetOperator}
            operatorsConfig={operatorOdds}
            buttonProps={
                Object.keys(operatorOdds).length > 1
                    ? {
                          disableElevation: true,
                          variant: 'outlined'
                      }
                    : undefined
            }
            onSelectOperator={(e: TBetOperator) => {
                dispatch(updateBetOperator(e))
            }}
        />
    )
}
