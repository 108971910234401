import { useEffect, useState } from 'react'

export const fetchLocation = () =>
    fetch(
        `https://pro.ip-api.com/json?key=${process.env.REACT_APP_IP_API_KEY}&fields=countryCode`
    )
        .then((res) => res.json())
        .then((json) => json?.countryCode || 'n/a')
        .catch(() => {
            return 'n/a'
        })

export const useLocation = () => {
    const [location, setLocation] = useState('')
    useEffect(() => {
        ;(async () => setLocation(await fetchLocation()))()
    }, [])
    return location
}
