import { IRunner } from '@matchem/matchem-common-ui'
import { Box, styled, Paper } from '@mui/material'
import Typography from '@mui/material/Typography'
import Colours from 'components/common/Colours'
import { useTranslation } from 'react-i18next'
import { runnerArrivalList } from 'utils/race-arrival'
import { onRunnerClick } from 'utils/runner'
import { selectRaceId } from 'reducers/race/raceSlice'
import { useAppDispatch, useAppSelector } from 'app/hooks'

export const RaceArrival = ({
    runners,
    winnerRunner,
    arrival
}: {
    runners: IRunner[]
    winnerRunner: IRunner
    arrival: string
}) => {
    const { t } = useTranslation('common')
    const dispatch = useAppDispatch()
    const raceId = useAppSelector(selectRaceId)!

    return (
        <Box flexDirection="column" alignItems="start">
            <Typography variant="h2" sx={{ mr: 1.5 }}>
                {t('arrival')}&nbsp;
                {winnerRunner.redkm}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    mt: 1.25
                }}
            >
                {runnerArrivalList(arrival, runners).map((runner) => (
                    <Paper
                        elevation={2}
                        key={runner.id}
                        sx={{
                            backgroundColor: 'background.default',
                            cursor: 'pointer',
                            marginRight: 0.5,
                            width: 36,
                            height: 50
                        }}
                        onClick={() => onRunnerClick(runner, raceId, dispatch)}
                    >
                        <RunnerItem>
                            <Typography
                                variant="h4"
                                sx={{
                                    my: 0.5,
                                    fontWeight: 800
                                }}
                            >
                                {runner?.saddle}
                            </Typography>
                            <Colours
                                coloursSrc={runner.coloursPng}
                                width={23}
                                height={21}
                            />
                        </RunnerItem>
                    </Paper>
                ))}
            </Box>
        </Box>
    )
}

const RunnerItem = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}))
