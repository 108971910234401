import {
    IRace,
    IRunner,
    isFinished,
    isUpcoming,
    isImminent,
    OperatorBetType,
    getFormattedBetTypes,
    Bet
} from '@matchem/matchem-common-ui'
import {
    List,
    ListItem,
    listItemClasses,
    ListItemIcon,
    ListItemText,
    listItemTextClasses,
    styled
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { IMeeting } from 'models/meeting'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'
import { selectRunnerByRaceId } from 'reducers/runner/runnerSlice'
import { formatNumber, formatUnit } from 'utils/helpers'
import { buildImgUrl } from 'utils/url'
import { RaceName } from '../RaceName'
import { WinnersTips } from './WinnersTips'
import { RaceArrival } from './RaceArrival'
import React, { useCallback, useEffect } from 'react'
import { getWinnerRunnerAsync } from 'reducers/runner/runnerThunks'

export const RaceInfo = ({
    race,
    meeting,
    runners,
    bets,
    operatorLink
}: {
    race: IRace
    meeting: IMeeting
    runners?: IRunner[]
    bets?: Bet[]
    operatorLink?: string | null
}) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common')
    const betOperator = useAppSelector(selectBetOperator)
    const { winnerRunner } = useAppSelector(selectRunnerByRaceId(race.id))

    useEffect(() => {
        if (isFinished(race.raceStatus, race.status)) {
            dispatch(getWinnerRunnerAsync(race.id))
        }
    }, [dispatch, race.id, race.raceStatus, race.status])

    const onClickBetTypes = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (operatorLink) window.open(operatorLink, '_blank')
            event.stopPropagation()
        },
        [operatorLink]
    )

    return (
        <ListRoot>
            <RaceName race={race} meeting={meeting} />
            <ListItem>
                <StyledListItemIcon>
                    {race.specialty && (
                        <img
                            alt="infos-course"
                            src={
                                ['H', 'S', 'C'].includes(race.specialty)
                                    ? buildImgUrl('/img/race/disc-O.svg')
                                    : buildImgUrl(
                                          `/img/race/disc-${race.specialty}.svg`
                                      )
                            }
                            width={24}
                            height={18}
                        />
                    )}
                </StyledListItemIcon>
                <StyledListItemText
                    primary={
                        <span>
                            <span>
                                {race
                                    .caraList1!.split(' - ')
                                    .map((cara, index) => (
                                        <span key={`cara-${index}`}>
                                            {index > 0 && <span> | </span>}
                                            <span>{cara}</span>
                                        </span>
                                    ))}
                            </span>
                            <span> | </span>
                            <span>
                                {race.numberOfRunners}&nbsp;{t('runners')}
                            </span>
                            <span> | </span>
                            <span>
                                {race.totalPrize &&
                                    race.prizeCurrency &&
                                    formatUnit(
                                        formatNumber(race.totalPrize),
                                        race.prizeCurrency
                                    )}
                            </span>
                        </span>
                    }
                />
            </ListItem>
            <ListItem>
                <StyledListItemIcon>
                    <img
                        alt="infos-parcours"
                        src={buildImgUrl('/img/race/track.svg')}
                        width={24}
                        height={24}
                    />
                </StyledListItemIcon>
                <StyledListItemText
                    primary={
                        <span>
                            {race.caraList2 &&
                                race.caraList2
                                    .split(' - ')
                                    .slice(1)
                                    .map((cara, index) => (
                                        <span key={`cara-${index}`}>
                                            {index > 0 && <span> | </span>}
                                            <span>{cara}</span>
                                        </span>
                                    ))}
                            {` | ${t('ground', {
                                value: race.liveGoing
                                    ? t(race.liveGoing)
                                    : t('Bon')
                            })}
                      ${t(race.liveGoing!) || t('Bon')}`}
                        </span>
                    }
                />
            </ListItem>
            {!isFinished(race.raceStatus, race.status) && betOperator && (
                <ListItem>
                    <OperatorBetType
                        operatorBetTypes={getFormattedBetTypes(bets)}
                        betOperator={betOperator}
                        onClick={onClickBetTypes}
                    />
                </ListItem>
            )}
            <ListItem>
                {runners &&
                    (isUpcoming(race.raceStatus) ||
                    isImminent(race.raceStatus) ? (
                        <WinnersTips runners={runners} />
                    ) : (
                        winnerRunner &&
                        race.arrival && (
                            <RaceArrival
                                arrival={race.arrival}
                                runners={runners}
                                winnerRunner={winnerRunner}
                            />
                        )
                    ))}
            </ListItem>
        </ListRoot>
    )
}

const ListRoot = styled(List)(({ theme }) => ({
    paddingTop: theme.spacing(0),

    [`& .${listItemClasses['gutters']}`]: {
        padding: theme.spacing(0, 0, 2, 1.25),
        alignItems: 'flex-start'
    },

    [`& .${listItemTextClasses['root']}`]: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0)
    }
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    [`&.${listItemTextClasses['primary']}`]: {
        fontSize: '14px'
    },

    [theme.breakpoints.up('sm')]: {
        alignSelf: 'center'
    }
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(4.5),
    alignItems: 'start'
}))
