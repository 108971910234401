import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { TOperatorOdds } from 'models/operator-odds'
import ConfigBilto from '../../configs/bilto.json'
import ConfigClairefontaine from '../../configs/clairefontaine.json'
import ConfigFranceGuadeloupe from '../../configs/france-antilles-guadeloupe.json'
import ConfigFranceMartinique from '../../configs/france-antilles-martinique.json'
import ConfigFranceGuyane from '../../configs/france-guyane.json'
import ConfigJhm from '../../configs/jhm.json'
import ConfigLaGazette from '../../configs/la-gazette.json'
import ConfigLeProgres from '../../configs/le-progres.json'
import ConfigOuestFrance from '../../configs/ouest-france.json'
import ConfigParisCourses from '../../configs/paris-courses.json'
import ConfigSna from '../../configs/sna.json'
import ConfigTierceMagazine from '../../configs/tierce-magazine.json'
import ConfigWeekEnd from '../../configs/week-end.json'
import ConfigZitata from '../../configs/zitata.json'
import { RootState } from '../store'

export interface ConfigState {
    value: IConfig | undefined
    clientKey: string | null
    withScroll: boolean
    quinteOnly: boolean
    statisticsConsents: boolean
    adsConsents: boolean
    disableBet: boolean
}

export interface IConfig {
    operatorOdds: TOperatorOdds
    isPremium?: string[]
    isPMH?: string[]
    disableLogoClickable?: boolean
}

export const getConfig = (key?: string | null): IConfig | undefined => {
    switch (key) {
        case 'la-gazette':
            return ConfigLaGazette
        case 'le-progres':
            return ConfigLeProgres
        case 'france-antilles-guadeloupe':
            return ConfigFranceGuadeloupe
        case 'france-antilles-martinique':
            return ConfigFranceMartinique
        case 'france-guyane':
            return ConfigFranceGuyane
        case 'jhm':
            return ConfigJhm
        case 'sna':
            return ConfigSna
        case 'ouest-france':
            return ConfigOuestFrance
        case 'bilto':
            return ConfigBilto
        case 'paris-courses':
            return ConfigParisCourses
        case 'tierce-magazine':
            return ConfigTierceMagazine
        case 'week-end':
            return ConfigWeekEnd
        case 'zitata':
            return ConfigZitata
        case 'clairefontaine':
            return ConfigClairefontaine
        default:
            return undefined
    }
}

const getInfoConsent = (value: string | boolean | null | undefined): boolean =>
    (typeof value === 'string' && value === 'true') ||
    (typeof value === 'boolean' && value)

const initialState: ConfigState = {
    value: getConfig(),
    clientKey: null,
    withScroll: false,
    quinteOnly: false,
    statisticsConsents: false,
    adsConsents: false,
    disableBet: false
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateConfig: (
            state,
            { payload }: PayloadAction<string | null | undefined>
        ) => {
            if (payload) {
                state.clientKey = payload
                state.value = getConfig(payload)
            }
        },
        updateWithScroll: (state, { payload }: PayloadAction<boolean>) => {
            state.withScroll = payload
        },
        updateQuinteOnly: (state, { payload }: PayloadAction<boolean>) => {
            state.quinteOnly = payload
        },
        updateStatisticsConsents: (
            state,
            { payload }: PayloadAction<boolean | string | null | undefined>
        ) => {
            state.statisticsConsents = getInfoConsent(payload)
        },
        updateAdsConsents: (
            state,
            { payload }: PayloadAction<boolean | string | null | undefined>
        ) => {
            state.adsConsents = getInfoConsent(payload)
        },
        updateDisableBet: (
            state,
            { payload }: PayloadAction<boolean | string | null | undefined>
        ) => {
            state.disableBet = getInfoConsent(payload)
        }
    }
})

export const {
    updateConfig,
    updateWithScroll,
    updateQuinteOnly,
    updateAdsConsents,
    updateDisableBet,
    updateStatisticsConsents
} = configSlice.actions

export const selectConfig = (state: RootState) => state.config.value as IConfig

export const selectClientKey = (state: RootState) => state.config.clientKey

export const selectDisableLogoClickable = createSelector(
    selectConfig,
    (config) => config?.disableLogoClickable
)

export const selectOperatorOdds = (state: RootState) =>
    state.config.value?.operatorOdds

export const selectWithScroll = (state: RootState) => state.config.withScroll

export const selectQuinteOnly = (state: RootState) => state.config.quinteOnly

export const selectDisableBet = (state: RootState) => state.config.disableBet

export default configSlice.reducer
