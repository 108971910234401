import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRunners,
  getStatsRunnersByRaceId,
  getWinnerRunner,
  getWinnerRunners,
} from "services/firestore";

export const getRunnersAsync = createAsyncThunk(
  "races/getRunners",
  async (raceId: number) => {
    const runners = await getRunners(raceId);
    return { raceId, runners };
  }
);

export const getWinnerRunnerAsync = createAsyncThunk(
  "races/getWinnerRunner",
  async (raceId: number) => {
    const winnerRunner = await getWinnerRunner(raceId);
    return { raceId, winnerRunner };
  }
);

export const getWinnerRunnersAsync = createAsyncThunk(
  "meeting/getWinnerRunners",
  async (meetingId: number) => {
    const winnerRunner = await getWinnerRunners(meetingId);
    return { meetingId, winnerRunner };
  }
);

export const getStatsRunnersByRaceIdAsync = createAsyncThunk(
  "meeting/getStatsRunnersByRaceId",
  async (raceId: number) => {
    return await getStatsRunnersByRaceId(raceId);
  }
);
