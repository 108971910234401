import IconButton from '@mui/material/IconButton'
import { buildImgUrl } from 'utils/url'
import { useAppDispatch } from 'app/hooks'
import { updatePage } from 'reducers/page/pageSlice'

export const HomeButton = (): JSX.Element => {
    const dispatch = useAppDispatch()
    return (
        <IconButton
            sx={{
                backgroundColor: 'background.paper',
                width: '52px',
                height: '34px',
                borderRadius: '17px',
                marginRight: 1
            }}
            onClick={() => dispatch(updatePage('home'))}
            aria-label="back"
            size="large"
        >
            <img src={buildImgUrl('/img/common/home.svg')} alt="home-button" />
        </IconButton>
    )
}
