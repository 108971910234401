import {
  createSelector,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IRunner, IStatistics } from "@matchem/matchem-common-ui";
import { ApiStatus } from "models/api";
import {
  getRunnersAsync,
  getWinnerRunnerAsync,
  getWinnerRunnersAsync,
  getStatsRunnersByRaceIdAsync,
} from "./runnerThunks";

export interface RunnerState {
  value: Record<
    string,
    {
      runners: IRunner[] | undefined;
      winnerRunner: IRunner | undefined;
    }
  >;
  status: ApiStatus;
  winnerRunnersByMeetingId: Record<string, IRunner[] | null>;
  statsRunnersByRaceId: IStatistics[] | undefined;
  runner: IRunner | undefined;
}

const initialState: RunnerState = {
  value: {},
  status: "idle",
  runner: undefined,
  winnerRunnersByMeetingId: {},
  statsRunnersByRaceId: undefined,
};

export const runnerSlice = createSlice({
  name: "runner",
  initialState,
  reducers: {
    updateRunner: (state, action: PayloadAction<IRunner>) => {
      state.runner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWinnerRunnerAsync.fulfilled, (state, action) => {
        const { winnerRunner, raceId } = action.payload;
        if (winnerRunner) {
          state.value[raceId] = state.value[raceId] || {};
          state.value[raceId].winnerRunner = winnerRunner;
        }
      })
      .addCase(getRunnersAsync.fulfilled, (state, action) => {
        const { runners, raceId } = action.payload;
        if (runners) {
          state.value[raceId] = state.value[raceId] || {};
          state.value[raceId].runners = runners;
        }
      })
      .addCase(getWinnerRunnersAsync.fulfilled, (state, action) => {
        const { winnerRunner, meetingId } = action.payload;
        state.winnerRunnersByMeetingId[meetingId] = winnerRunner;
      })
      .addCase(getStatsRunnersByRaceIdAsync.fulfilled, (state, action) => {
        const statsRunners = action.payload;
        if (statsRunners) {
          state.statsRunnersByRaceId = statsRunners;
        }
      })
      .addMatcher(
        isPending(
          getWinnerRunnerAsync,
          getRunnersAsync,
          getStatsRunnersByRaceIdAsync
        ),
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isRejected(
          getWinnerRunnerAsync,
          getRunnersAsync,
          getStatsRunnersByRaceIdAsync
        ),
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(
        isFulfilled(
          getWinnerRunnerAsync,
          getRunnersAsync,
          getStatsRunnersByRaceIdAsync
        ),
        (state) => {
          state.status = "idle";
        }
      );
  },
});

export const { updateRunner } = runnerSlice.actions;
const selectState = (state: RootState) => state.runner;
export const selectRunner = createSelector(
  [selectState],
  (state) => state.runner
);
export const selectRunners = createSelector(
  [selectState],
  (state) => state.value
);
export const selectWinnersRunners = createSelector(
  [selectState],
  (state) => state.winnerRunnersByMeetingId
);
export const selectStatsRunners = createSelector(
  [selectState],
  (state) => state.statsRunnersByRaceId
);

export const selectStatsRunnerByRunnerId = (runnerId: string) =>
  createSelector(selectStatsRunners, (statsRunner) =>
    statsRunner?.find((r) => r.id === runnerId)
  );

export const selectWinnerRunnersFromMeeting = (meetingId: number) =>
  createSelector(
    selectWinnersRunners,
    (runnersByMeeting) => runnersByMeeting[meetingId]
  );

export const selectRunnersByRaceID = (raceId: number) =>
  createSelector(selectRunners, (runners) =>
    runners[raceId]?.runners?.filter((r) => r.raceId === raceId)
  );

export const selectRunnerByRaceId = (raceId: number) =>
  createSelector(
    selectRunners,
    (runners) => runners[raceId] || { winnerRunner: undefined }
  );

export default runnerSlice.reducer;
