import { Box, SxProps, useMediaQuery } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { selectClientKey, selectDisableBet } from 'reducers/config/configSlice'
import theme from 'style/theme'

type BetOfferDisplayMode =
    | 'normal'
    | 'one-line'
    | 'compressed'
    | 'light'
    | 'light-compressed'

type BetOfferSliderDirection = 'column' | 'row'

type BetOfferTranslation = {
    title: string
}

type BetinOptions = {
    direction?: BetOfferSliderDirection
    defaultDisplay?: BetOfferDisplayMode
    expandedDisplay?: Omit<BetOfferDisplayMode, 'one-line'>
    expandedFirst?: boolean
    lightMode?: boolean
    maxOffers?: number
    translation?: BetOfferTranslation
    context?: {
        meetingNumber?: number
        raceNumber?: number
        runnerNumber?: number
        runnerList?: string // ex: "1,2,3"
        raceId?: number
        raceDate?: string
        meetingId?: number
        isQuinte?: boolean
    }
}

type BetinComponentProps = {
    component: 'block' | 'offer' | 'operators'
    options?: BetinOptions
    sx?: SxProps
}

export const BetinComponent = (props: BetinComponentProps) => {
    const { component, options, sx } = props
    const disableBet = useAppSelector(selectDisableBet)
    const clientId: string | null = useAppSelector(selectClientKey)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    if (!clientId || disableBet) return null
    return (
        <Box
            sx={{ px: 1, pt: 2, pb: 3, ...sx }}
            data-options={JSON.stringify({
                direction: isMobile ? 'column' : 'row',
                defaultDisplay: 'compressed',
                expandedDisplay: 'compressed',
                translation: { title: "L'info qui fait la différence !" },
                ...options
            })}
            data-type={`betin_${component}`}
        />
    )
}
