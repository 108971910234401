import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './i18n'
import App from './App'
import { store } from 'reducers/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'

if (!(window as any)?.renderMatchemWidget) {
    ;(window as any).renderMatchemWidget = function () {
        const container = document.querySelector('.matchem_widget')
        const root = createRoot(container!)
        return root.render(
            <StrictMode>
                <Provider store={store}>
                    <App domElement={container} />
                </Provider>
            </StrictMode>
        )
    }
}

const renderWidget = (attempt = 1) => {
    const WidgetDivs = document.querySelector('.matchem_widget')
    if (WidgetDivs) {
        const root = createRoot(WidgetDivs)
        root.render(
            <StrictMode>
                <Provider store={store}>
                    <App domElement={WidgetDivs} />
                </Provider>
            </StrictMode>
        )
    } else if (attempt <= 3) {
        setTimeout(() => renderWidget(attempt + 1), 350)
    }
}

window.addEventListener('DOMContentLoaded', () => {
    renderWidget()
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
