import { useAppSelector } from 'app/hooks'
import { DependencyList, useEffect } from 'react'
import { selectClientKey, selectDisableBet } from 'reducers/config/configSlice'

/**
 * @doc https://betin-widget-int.appspot.com/
 */
export const useBetinWidget = (dependencies: DependencyList) => {
    const disableBet = useAppSelector(selectDisableBet)
    const renderBetinWidget = () => {
        if ((window as any).renderBetinWidget) {
            ;(window as any).renderBetinWidget()
        }
    }
    const clientId = useAppSelector(selectClientKey)

    useEffect(() => {
        if (!clientId) return
        const src = `${process.env.REACT_APP_BETIN_WIDGET_URL}/get/${clientId}`
        if (document.querySelector(`script[src="${src}"]`)) return
        // Remove previous client script
        document
            .querySelectorAll(
                `script[src^="${process.env.REACT_APP_BETIN_WIDGET_URL}/get/"]`
            )
            .forEach((script) => script.remove())
        const script = document.createElement('script')
        script.src = src
        script.async = true
        document.body.appendChild(script)
    }, [clientId])

    useEffect(() => {
        if (!disableBet) renderBetinWidget()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableBet, ...dependencies])
}
