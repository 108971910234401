import { useMemo } from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectDate } from '../../reducers/date/dateSlice'
import { formatTitleDate } from '../../utils/date'
import styles from './SelectedDate.module.css'

export const SelectedDate = () => {
    const selectedDate = useAppSelector(selectDate)

    const date = useMemo(() => {
        return formatTitleDate(selectedDate)
    }, [selectedDate])

    return (
        <span className={styles.date} data-testid="date">
            {date}
        </span>
    )
}
