import { useEffect, useState } from "react";

export const DEFAULT_COLOUR =
  "https://www.paris-turf.com/statics/img/runner/default-colours.png";

const Colours = ({
  coloursSrc,
  width = 26,
  height = 20,
}: {
  coloursSrc: string;
  width?: number;
  height?: number;
}): JSX.Element => {
  const [src, setSrc] = useState(DEFAULT_COLOUR);

  useEffect(() => {
    if (coloursSrc) {
      fetch(
        `https://www.paris-turf.com/colours/png${coloursSrc.substring(8)}`
      ).then((res) => {
        if (res.status === 200)
          setSrc(
            `https://www.paris-turf.com/colours/png${coloursSrc.substring(8)}`
          );
      });
    }
  }, [coloursSrc]);

  return <img src={src} alt={coloursSrc} width={width} height={height} />;
};

export default Colours;
