import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { selectFormattedDate } from "../date/dateSlice";
import { selectConfig } from "../config/configSlice";
import { IMeeting } from "models/meeting";

export interface MeetingsState {
  value: Record<string, Array<IMeeting>>;
}

const initialState: MeetingsState = {
  value: {},
};

export const meetingsSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    updateMeetings: (state, action) => {
      state.value[action.payload.selectedDate] = action.payload.meetings;
    },
  },
});

export const { updateMeetings } = meetingsSlice.actions;

const meetingsValue = (state: RootState) => state.meetings.value;
const selectMeetingsByDate = createSelector(
  [selectFormattedDate, meetingsValue],
  (date, meetings) => meetings[date] ?? []
);
export const selectMeetings = createSelector(
  [selectMeetingsByDate, selectConfig],
  (meetings, config) =>
    meetings.reduce<IMeeting[]>((acc, meeting) => {
      if (config.isPremium && meeting.isPremium && meeting.pmuNumber! > 0) {
        if (
          config.isPremium.length === 0 ||
          config.isPremium.includes(meeting.location)
        ) {
          acc.push(meeting);
          return acc;
        }
      }

      if (config.isPMH && (!meeting.isPremium || meeting.pmuNumber === 0)) {
        if (
          config.isPMH.length === 0 ||
          config.isPMH.includes(meeting.location)
        ) {
          acc.push(meeting);
        }
      }

      return acc;
    }, [])
);
export const selectMeetingsByRace = (raceMeetingId?: number) =>
  createSelector(selectMeetingsByDate, (meetings) => {
    if (!raceMeetingId) {
      return undefined;
    }

    return meetings.find((meeting) => meeting.id === raceMeetingId);
  });

export const selectPremiumMeetings = createSelector(
  selectMeetings,
  (meetings) =>
    meetings.filter((meeting) => meeting.isPremium && meeting.pmuNumber! > 0)
);

export const selectPMHMeetings = createSelector(selectMeetings, (meetings) =>
  meetings.filter((meeting) => !meeting.isPremium || meeting.pmuNumber === 0)
);

export default meetingsSlice.reducer;
