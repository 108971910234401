import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface RaceState {
    value: number | undefined
}

const initialState: RaceState = {
    value: undefined
}

export const raceSlice = createSlice({
    name: 'race',
    initialState,
    reducers: {
        updateRaceId: (state, action: PayloadAction<RaceState['value']>) => {
            state.value = action.payload
        }
    }
})

export const { updateRaceId } = raceSlice.actions

export const selectRaceId = (state: RootState) => state.race.value

export default raceSlice.reducer
