import ToggleButton, { toggleButtonClasses } from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { TRacesMeetingsToggle } from "models/races-meetings-toggle";
import { Paper, styled, toggleButtonGroupClasses } from "@mui/material";

export const ToggleButtons = ({
  racesOrMeetings,
  setRacesOrMeetings,
}: {
  racesOrMeetings: TRacesMeetingsToggle;
  setRacesOrMeetings: Dispatch<SetStateAction<TRacesMeetingsToggle>>;
}) => {
  const { t } = useTranslation("common");

  const handleToggle = (event: any, newAlignment: TRacesMeetingsToggle) => {
    setRacesOrMeetings(newAlignment);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        border: "none",
        borderRadius: "18px",
      }}
    >
      <StyledToggleButtonGroup
        value={racesOrMeetings}
        exclusive
        onChange={handleToggle}
      >
        <StyledToggleButton
          value={"races"}
          disabled={racesOrMeetings === "races"}
          aria-label="races"
          data-testid="races"
        >
          {t("races")}
        </StyledToggleButton>
        <StyledToggleButton
          value={"meetings"}
          disabled={racesOrMeetings === "meetings"}
          aria-label="meetings"
          data-testid="meetings"
        >
          {t("meetings")}
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  );
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses["grouped"]}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    [`& .${toggleButtonClasses["root"]}:first-of-type`]: {
      borderRadius: "18px",
    },
    [`& .${toggleButtonClasses["root"]}:not(:first-of-type)`]: {
      borderRadius: "18px",
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  width: 76,
  height: 30,
  textTransform: "capitalize",
  border: 0,
  borderRadius: "18px",
  fontSize: "12px",
  margin: "2px",

  [`&:hover`]: {
    backgroundColor: "inherit",
  },

  [`&.Mui-selected`]: {
    backgroundColor: "white",
    fontWeight: "bold",
    boxShadow: "0.5px 0.5px 2px 0px #c8c8c8",
  },
}));
