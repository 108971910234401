import {
    IRace,
    IRunner,
    IStatistics,
    RunnerIcons
} from '@matchem/matchem-common-ui'
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    listItemClasses,
    listItemTextClasses,
    styled,
    useMediaQuery
} from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { BetinComponent } from 'components/betin'
import { RunnerStats } from 'components/runner/RunnerStats'
import { Horse } from 'models/horse'
import { IMeeting } from 'models/meeting'
import { TEmoji } from 'models/runner'
import { useTranslation } from 'react-i18next'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'
import { formatUnit } from 'utils/helpers'
import { isFinished } from 'utils/race-status'
import { buildImgUrl } from 'utils/url'
import theme from '../../style/theme'
import Colours from '../common/Colours'
import { Emoji } from '../common/emoji'
import { RaceName } from '../races/RaceName'
import { RunnerSelector } from '../runner/RunnerSelector'

export const HorseInfo = ({
    runner,
    horse,
    race,
    meeting,
    runners,
    statsRunner
}: {
    runner: IRunner
    horse: Horse
    race: IRace
    meeting: IMeeting
    runners: IRunner[]
    statsRunner: IStatistics
}) => {
    const finished = isFinished(race.raceStatus, race.status)
    const { t } = useTranslation('common')
    const selectedBetOperator = useAppSelector(selectBetOperator)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <ListRoot>
            <RaceName race={race} meeting={meeting} />
            <RunnerSelector
                runners={runners}
                currentRunner={runner}
                race={race}
            />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    width="max-content"
                >
                    <Grid item xs={12}>
                        <StyledListItem>
                            <ListItemIconSaddle>
                                <DivWrapper>
                                    <Grid item sm={9}>
                                        <TypographySaddleText variant="h3">
                                            {runner?.saddle}
                                        </TypographySaddleText>
                                    </Grid>
                                </DivWrapper>
                            </ListItemIconSaddle>
                            <StyledListItemText
                                primary={
                                    <DivTitleWrapper>
                                        <Colours
                                            coloursSrc={runner.coloursPng}
                                            width={31}
                                            height={27}
                                        />
                                        <TypographyTitleName variant="h3">
                                            {runner.horseName}
                                        </TypographyTitleName>
                                    </DivTitleWrapper>
                                }
                            />
                            {selectedBetOperator && (
                                <DivOdd finished={finished}>
                                    {(runner as any).emoji && (
                                        <>
                                            {!isMobile && (
                                                <EmojiSpan>
                                                    {t('table-head-emoji')}
                                                </EmojiSpan>
                                            )}
                                            <Box marginX={1} display={'flex'}>
                                                <Emoji
                                                    emoji={
                                                        (runner as any)
                                                            .emoji as TEmoji
                                                    }
                                                />
                                            </Box>
                                        </>
                                    )}
                                </DivOdd>
                            )}
                        </StyledListItem>
                        <ListItem>
                            <StyledListItemIcon>
                                {race.specialty && (
                                    <img
                                        alt="infos-course"
                                        src={
                                            ['H', 'S', 'C'].includes(
                                                race.specialty
                                            )
                                                ? buildImgUrl(
                                                      '/img/race/disc-O.svg'
                                                  )
                                                : buildImgUrl(
                                                      `/img/race/disc-${race.specialty}.svg`
                                                  )
                                        }
                                        width={24}
                                        height={18}
                                    />
                                )}
                            </StyledListItemIcon>
                            <div>
                                <StyledListItemText
                                    primary={
                                        <span>
                                            <span>
                                                {t(
                                                    `horse-profile-sex-${horse.sex}`
                                                )}
                                            </span>
                                            <span> | </span>
                                            <span>
                                                {horse.age}&nbsp;
                                                {t('horse-profile-year')}
                                            </span>
                                            {horse.coat && (
                                                <>
                                                    <span> | </span>
                                                    <span>
                                                        {t(
                                                            `horse-coat-${horse.coat.trim()}`
                                                        )}
                                                    </span>
                                                </>
                                            )}
                                        </span>
                                    }
                                />
                                <DivRowItems>
                                    <StyledListItemText
                                        primary={
                                            <span>
                                                <SpanText>
                                                    {t('gains')}:{' '}
                                                </SpanText>
                                                <span>
                                                    {race.totalPrize &&
                                                        race.prizeCurrency &&
                                                        formatUnit(
                                                            race.totalPrize,
                                                            race.prizeCurrency
                                                        )}
                                                </span>
                                                {(runner.blinkers ||
                                                    runner.hood ||
                                                    runner.tongueTie ||
                                                    ![
                                                        undefined,
                                                        null,
                                                        '',
                                                        'FF'
                                                    ].includes(
                                                        runner.shoeing
                                                    )) && <span> |&nbsp;</span>}
                                            </span>
                                        }
                                    />
                                    <RunnerIcons runner={runner} />
                                </DivRowItems>
                            </div>
                        </ListItem>

                        <ListItem>
                            <StyledListItemIcon>
                                {race.specialty && (
                                    <ImgIcon
                                        alt="cup"
                                        src={buildImgUrl('/img/race/cup.svg')}
                                    />
                                )}
                            </StyledListItemIcon>
                            <RunnerStats statsRunner={statsRunner} />
                        </ListItem>
                        <ListItem>
                            <StyledListItemIcon>
                                <ImgIcon
                                    src={buildImgUrl(
                                        '/img/people.svg',
                                        'widget'
                                    )}
                                    alt="people"
                                />
                            </StyledListItemIcon>
                            <StyledListItemText
                                primary={
                                    <span>
                                        {race.specialty === 'A' ? (
                                            <SpanText>
                                                {t('horse-driver')}:{' '}
                                            </SpanText>
                                        ) : (
                                            <SpanText>
                                                {t('horse-jockey')}:{' '}
                                            </SpanText>
                                        )}
                                        <span>{runner.jockeyName}</span>
                                        <span> | </span>
                                        <SpanText>
                                            {t('horse-trainer')}:{' '}
                                        </SpanText>
                                        <span>{runner.trainerName}</span>
                                        <span> | </span>
                                        <SpanText>
                                            {t('horse-owner')}:{' '}
                                        </SpanText>
                                        <span>{runner.ownerName}</span>
                                    </span>
                                }
                            />
                        </ListItem>
                    </Grid>
                </Grid>
                <BetinComponent
                    component="block"
                    options={{
                        maxOffers: 3,
                        direction: 'column',
                        defaultDisplay: 'light-compressed',
                        expandedDisplay: 'light-compressed',
                        context: {
                            raceNumber: race.number,
                            meetingNumber: meeting.pmuNumber,
                            runnerNumber: runner.saddle
                        }
                    }}
                    sx={{
                        width: '100%',
                        paddingLeft: 0,
                        paddingRight: 0
                    }}
                />
            </Box>
        </ListRoot>
    )
}

const ListRoot = styled(List)(({ theme }) => ({
    paddingTop: theme.spacing(0),

    [`& .${listItemClasses['gutters']}`]: {
        padding: theme.spacing(0, 0, 1.5, 0)
    },

    [`& .${listItemTextClasses['root']}`]: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0)
    }
}))

const ListItemIconSaddle = styled(ListItemIcon)(({ theme }) => ({
    width: '24px',
    fontWeight: 'bold',
    color: 'black',
    minWidth: theme.spacing(4),
    alignSelf: 'center',
    justifyContent: 'center'
}))

const TypographySaddleText = styled(Typography)(() => ({
    textAlign: 'center'
}))

const DivTitleWrapper = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    alignItems: 'center',
    display: 'flex'
}))

const TypographyTitleName = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(4.5),
    justifyContent: 'center'
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    maxWidth: 'max-content',
    [`& .${listItemTextClasses['primary']}`]: {
        fontSize: '14px'
    },

    [theme.breakpoints.up('sm')]: {
        alignSelf: 'center'
    }
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    paddingRight: theme.spacing(0)
}))

const DivWrapper = styled('div')(({ theme }) => ({
    verticalAlign: 'middle',
    display: 'flex',
    overflow: 'hidden',

    '&.raceRow': {
        marginTop: theme.spacing(0.5)
    }
}))

const EmojiSpan = styled('span')(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: 12
}))

const SpanText = styled('span')(({ theme }) => ({
    color: theme.palette.grey[500]
}))

const ImgIcon = styled('img')(({ theme }) => ({
    marginLeft: 1,
    verticalAlign: 'middle',

    [theme.breakpoints.down('sm')]: {
        height: 16,
        width: 22
    }
}))

const DivRowItems = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}))

const DivOdd = styled('div', {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'finished'
})<{ finished: boolean }>(({ finished }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, !finished ? 0 : 1)
}))
