import {
  IMeeting,
  IPastRace,
  IRace,
  IRunner,
} from "@matchem/matchem-common-ui";
import { getPastRace } from "models/past-race";
import { getMeeting } from "services/firestore";

export const addMeetingNameToRaces = async (
  races: IRace[]
): Promise<IRace[]> => {
  const meetings: Array<IMeeting | null> = await Promise.all(
    races.map((race) => (race?.meetingId ? getMeeting(race.meetingId) : null))
  );

  return races.map(
    (race, index) => race && { ...race, meetingName: meetings[index]?.name }
  );
};

export const buildPastRaces = async (
  races: IRace[],
  runners: IRunner[]
): Promise<IPastRace[]> => {
  const racesMeetings = await addMeetingNameToRaces(races);

  return racesMeetings.reduce((acc: IPastRace[], rm: IRace) => {
    const runner = runners.find((runner) => runner.raceId === rm.id);
    runner && acc.push(getPastRace(rm, runner));
    return acc;
  }, []);
};

export const someRunnersInRace = (runners: IRunner[], race: IRace): boolean =>
  runners.some((runner) => runner.raceId === race.id);
