import { List, ListSubheader, styled } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectWithScroll } from "reducers/config/configSlice";
import { ReactNode } from "react";

export const ListCard = ({ children }: { children: ReactNode }) => {
  const withScroll = useAppSelector(selectWithScroll);

  return (
    <List
      sx={{
        width: "100%",
        backgroundColor: "background.paper",
        position: "relative",
        overflowY: "auto",
        maxHeight: withScroll ? 612 : "none",
        padding: 0,
      }}
    >
      {children}
    </List>
  );
};

export const ListSubHeaderCard = styled(ListSubheader)(({ theme }) => ({
  color: "inherit",
  paddingBottom: theme.spacing(0.5),
}));
