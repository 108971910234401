import { IRace } from "@matchem/matchem-common-ui";
import { add, isBefore, parse } from "date-fns";
import { IMeeting } from "models/meeting";
import { isFinished } from "./race-status";

interface IRaceMeeting {
  race: IRace;
  meeting: IMeeting;
}

export const isItemClosed = (race: IRace, meeting: IMeeting, date: Date) => {
  if (
    isFinished(race.raceStatus) ||
    (!race.raceStatus &&
      (!race.isPremium || meeting.pmuNumber === 0) &&
      race.time &&
      isBefore(add(parse(race.time, "HH:mm:ss", date), { minutes: 15 }), date))
  )
    return true;
  else return false;
};

export const getLiveAndNextRaces = (
  ongoingItems: IRaceMeeting[],
  closedItems: IRaceMeeting[],
  upcomingItems: IRaceMeeting[]
) => {
  const nextRaces = [...upcomingItems];
  if (closedItems.length >= 1 && nextRaces.length >= 0) {
    const lastClosed = closedItems[closedItems.length - 1];
    if (ongoingItems.length > 0) {
      if (ongoingItems.length === 1) {
        const upcomingEvents = nextRaces.length > 0 ? [nextRaces[0]] : [];
        nextRaces.splice(0, 1);
        return {
          liveItems: [lastClosed, ongoingItems[0], ...upcomingEvents],
          nextRaces,
        };
      } else if (ongoingItems.length === 2) {
        return {
          liveItems: [lastClosed, ongoingItems[0], ongoingItems[1]],
          nextRaces,
        };
      } else {
        ongoingItems
          .slice(2)
          .reverse()
          .map((ongoingItem) => nextRaces.unshift(ongoingItem));
        return {
          liveItems: [lastClosed, ongoingItems[0], ongoingItems[1]],
          nextRaces,
        };
      }
    } else {
      const upcomingEvents =
        nextRaces.length > 0
          ? nextRaces.length > 1
            ? [nextRaces[0], nextRaces[1]]
            : [nextRaces[0]]
          : [];
      nextRaces.splice(0, 2);
      return { liveItems: [lastClosed, ...upcomingEvents], nextRaces };
    }
  } else return { nextDayRaces: [...ongoingItems, ...upcomingItems] };
};
