import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectPage, updatePage } from "reducers/page/pageSlice";

export const BackButton = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector(selectPage);
  const goBack = () => {
    currentPage === "horse"
      ? dispatch(updatePage("race"))
      : dispatch(updatePage("home"));
  };

  return (
    <IconButton
      sx={{
        backgroundColor: "background.paper",
        width: "52px",
        height: "34px",
        borderRadius: "17px",
        marginRight: 1,
      }}
      onClick={goBack}
      aria-label="back"
      size="large"
    >
      <ArrowBackIcon
        height={"24"}
        width={"24"}
        sx={{
          marginRight: 2,
          marginLeft: 1.5,
          color: "primary.main",
        }}
      />
    </IconButton>
  );
};
