import {
    Bet,
    IRace,
    RaceCard,
    getBetinRunnersOdds,
    getFavoriteRunnerId,
    isFinished
} from '@matchem/matchem-common-ui'
import { useMediaQuery } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useLocation } from 'hooks/location'
import { useSession } from 'hooks/session'
import { IMeeting } from 'models/meeting'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { selectBetOperator } from 'reducers/betOperator/betOperatorSlice'
import { selectBetinRaceOdds } from 'reducers/betinOdds/selectors'
import { selectDisableBet, selectQuinteOnly } from 'reducers/config/configSlice'
import { selectPage, updatePage } from 'reducers/page/pageSlice'
import { getPronosmartTipsByRaceIdAsync } from 'reducers/pronosmartTips/pronosmartTipsThunks'
import { updateRaceId } from 'reducers/race/raceSlice'
import { selectRunnerByRaceId } from 'reducers/runner/runnerSlice'
import { getWinnerRunnerAsync } from 'reducers/runner/runnerThunks'
import { getRaceBettingTypesByRaceId } from 'services/firestore'
import theme from 'style/theme'
import { getAffiliateLink } from 'utils/url'

export const RaceCardW = ({
    race,
    meeting
}: {
    race: IRace
    meeting: IMeeting
}): JSX.Element => {
    const dispatch = useAppDispatch()
    const selectedBetOperator = useAppSelector(selectBetOperator)
    const disableBet = useAppSelector(selectDisableBet)
    const quinteOnly = useAppSelector(selectQuinteOnly)
    const { winnerRunner } = useAppSelector(selectRunnerByRaceId(race.id))
    const betinRaceOdds = useAppSelector(selectBetinRaceOdds(race.id))
    const [bets, setBets] = useState<Bet[]>([])
    const location = useLocation()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const sessionId = useSession('betin_widget')
    const page = useAppSelector(selectPage)

    useEffect(() => {
        let removed = false
        const getBettingTypes = async () => {
            const betTypes = await getRaceBettingTypesByRaceId(race.id)
            if (!removed) {
                setBets(betTypes || [])
            }
        }

        if (!isFinished(race.raceStatus, race.status)) {
            void getBettingTypes()
        }
        return () => {
            removed = true
        }
    }, [race.id, race.raceStatus, race.status])

    useEffect(() => {
        if (isFinished(race.raceStatus, race.status)) {
            dispatch(getWinnerRunnerAsync(race.id))
        }
    }, [dispatch, race.id, race.raceStatus, race.status])

    const winnerOdd = useMemo(() => {
        if (
            !winnerRunner ||
            !race ||
            !selectedBetOperator ||
            !isFinished(race.raceStatus, race.status)
        ) {
            return undefined
        }

        const betinRunnersOdds = betinRaceOdds
            ? getBetinRunnersOdds(betinRaceOdds)
            : undefined

        const odd = betinRunnersOdds?.[winnerRunner.id]
        return odd?.[selectedBetOperator].liveOdd
    }, [winnerRunner, race, betinRaceOdds, selectedBetOperator])

    const openRaceDetail = useCallback(() => {
        dispatch(updatePage('race'))
        dispatch(updateRaceId(race.id))
        dispatch(getPronosmartTipsByRaceIdAsync(race.id))
    }, [dispatch, race.id])

    const favoriteRunnerId = useMemo(() => {
        if (!betinRaceOdds || !selectedBetOperator) return undefined
        return getFavoriteRunnerId(betinRaceOdds, selectedBetOperator)
    }, [betinRaceOdds, selectedBetOperator])

    const favoriteRunner = useMemo(() => {
        const betinRaceOdd = betinRaceOdds?.odds.find(
            ({ operator }) => operator === selectedBetOperator
        )
        return betinRaceOdd?.runners.find(
            ({ runnerId }) => runnerId === favoriteRunnerId
        )
    }, [betinRaceOdds, selectedBetOperator, favoriteRunnerId])

    const operatorLink = useMemo(() => {
        const hasLink =
            !disableBet &&
            meeting?.pmuNumber !== undefined &&
            race?.number !== undefined &&
            !!selectedBetOperator
        if (!hasLink) return undefined
        return getAffiliateLink({
            operator: selectedBetOperator,
            meeting,
            race,
            runnerOdd: favoriteRunner,
            isMobile,
            componentId: 'co',
            page,
            sid: sessionId,
            country: location
        })
    }, [
        disableBet,
        selectedBetOperator,
        meeting,
        race,
        favoriteRunner,
        isMobile,
        page,
        sessionId,
        location
    ])

    const odds = useMemo(() => {
        if (!race) return undefined

        const betinRunnersOdds = betinRaceOdds
            ? getBetinRunnersOdds(betinRaceOdds)
            : undefined

        if (favoriteRunnerId && betinRunnersOdds) {
            return betinRunnersOdds[favoriteRunnerId]
        }
        return undefined
    }, [race, betinRaceOdds, favoriteRunnerId])

    const onClickBetTypes = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (operatorLink) window.open(operatorLink, '_blank')
            event.stopPropagation()
        },
        [operatorLink]
    )

    return (
        <>
            {selectedBetOperator && (
                <RaceCard
                    race={race}
                    bets={bets}
                    meeting={meeting}
                    odds={odds}
                    betOperator={selectedBetOperator}
                    operatorOddLink={operatorLink || undefined}
                    winnerRunner={{
                        horseName: winnerRunner?.horseName ?? '',
                        odd: winnerOdd!
                    }}
                    onClickCard={
                        quinteOnly
                            ? () =>
                                  operatorLink &&
                                  !isFinished(race.raceStatus, race.status) &&
                                  window.open(operatorLink)
                            : openRaceDetail
                    }
                    onClickOdd={() => {
                        operatorLink && window.open(operatorLink)
                    }}
                    onClickBetTypes={onClickBetTypes}
                    showPmhTag={true}
                    clickableOdd={!!operatorLink}
                    theming={{
                        meetingNumber: { backgroundColor: '#000' },
                        number: { backgroundColor: '#FFF' }
                    }}
                />
            )}
        </>
    )
}

export const RaceCardWrapper = memo(RaceCardW)
